import React from "react";

const NotFound = () => {
  return (
    <div className="body_404">
      <div className="container_404">
        <h1>404</h1>
        <p>Page not found :( </p>
        <a href="/" className="btn_404">
        Return to home page
        </a>
      </div>
    </div>
  );
};

export default NotFound;
