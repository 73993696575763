import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSevenCatecories } from "../action/MainAction";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import SingleProdModal from "./SingleProdModal";
// import { isLoadingStart } from '../redux/MainReducer'
// import AOS from 'aos'
// AOS.init({
// 	once: true, // whether animation should happen only once - while scrolling down
// });

const HomeGrids = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  // Orxan's code for modal open
  // const [openModal, setModalActive] = useState(false);

  const [modalActive, setModalActive] = useState(false);

  // Вызов переменной содержащий данные о семи продуктах
  const sevenCategoriesValue = useSelector(
    (state) => state.Data.sevenCategoriesValue
  );
  const loading = useSelector((state) => state.Data.loading);

  // Вызов api/categories/homepage/
  useEffect(() => {
    const currLang =
      i18next.language !== "dev" ? i18next.language.toLowerCase() : "en";
    if (i18next.language !== "dev") {
      dispatch(getSevenCatecories(currLang));
    }
  }, [i18next.language, dispatch]);

  // data-aos="zoom-in-down"

  return (
    <>
      <div className="my-12 sm:my-20">
        <div className="max-h-[1070px] lg:min-h-[1070px] lg:h-full max-w-[1440px] mx-auto grid grid-cols-7 grid-rows-8 lg:grid-cols-9 lg:grid-rows-18 gap-1">
          {/* 1 изображение из 7 продуктов */}

          <div className="aspect-h-1 aspect-w-1 bg-indigo-500 col-span-4 row-span-2 lg:col-span-4 lg:row-span-9 relative overflow-hidden group bg-[#efefefab]">
            <img
              data-aos="zoom-out"
              data-aos-once="true"
              loading="lazy"
              src={sevenCategoriesValue[3]?.image}
              alt="Buz Group Category Product"
              className={`h-full w-full object-cover group-hover:scale-105 transition-transform duration-700 ease-out ${
                !sevenCategoriesValue[3]?.image
                  ? "bg-gradient-to-t from-[rgba(255,255,255,0.8)] to-[rgba(255,255,255,0)] animate-pulse"
                  : ""
              }`}
            />

            <div className="absolute w-full content-none -bottom-[60px] group-hover:bottom-0 left-0 text-white bg-zinc-500/50 px-4 py-3 ease-in-out duration-500">
              <h6 className="text-xl font-taviraj">
                {sevenCategoriesValue[3]?.title.toUpperCase()}{" "}
              </h6>
              <p className="mt-1 hidden md:block font-dm">
                {sevenCategoriesValue[3]?.description}
              </p>
              <button
                onClick={() => setModalActive(true)}
                className="bg-[#50B3C1] text-sm sm:text-base hover:bg-[#419eab] border border-[#50B3C1] hover:border-white ease-in-out duration-500 rounded mt-4 py-2 sm:px-8 px-2"
              >
                {t("sendQuery")}
              </button>
            </div>
          </div>

          {/* 2 изображение из 7 продуктов */}
          <div className="aspect-h-1 aspect-w-1 bg-indigo-500 col-span-3 row-span-2 lg:col-span-3 lg:row-span-6 relative overflow-hidden group bg-[#efefefab]">
            <img
              data-aos="zoom-out"
              data-aos-once="true"

              loading="lazy"
              src={sevenCategoriesValue[1]?.image}
              alt="Buz Group Category Product"
              className={`h-full w-full object-cover group-hover:scale-105 transition-transform duration-700 ease-out ${
                !sevenCategoriesValue[1]?.image
                  ? "bg-gradient-to-t from-[rgba(255,255,255,0.8)] to-[rgba(255,255,255,0)] animate-pulse"
                  : ""
              }`}
            />
            <div className="absolute w-full content-none -bottom-[60px] group-hover:bottom-0 left-0 text-white bg-zinc-500/50 px-4 py-3 ease-in-out duration-500">
              <h6 className="text-xl font-taviraj ">
                {sevenCategoriesValue[1]?.title.toUpperCase()}
              </h6>
              <p className="mt-1 hidden md:block font-dm">
                {sevenCategoriesValue[1]?.description}
              </p>
              <button
                onClick={() => setModalActive(true)}
                className="bg-[#50B3C1] text-sm sm:text-base hover:bg-[#419eab] border border-[#50B3C1] hover:border-white ease-in-out duration-500 rounded mt-4 py-2 sm:px-8 px-2"
              >
                {t("sendQuery")}
              </button>
            </div>
          </div>

          {/* 3 изображение из 7 продуктов */}
          <div className="aspect-h-1 aspect-w-1 bg-indigo-500 col-span-3 row-span-2 lg:col-span-2 lg:row-span-9 relative overflow-hidden group bg-[#efefefab]">
            <img
              data-aos="zoom-out"
              data-aos-once="true"

              loading="lazy"
              src={sevenCategoriesValue[4]?.image}
              alt="Buz Group Category Product"
              className={`h-full w-full object-cover group-hover:scale-105 transition-transform duration-700 ease-out ${
                !sevenCategoriesValue[4]?.image
                  ? "bg-gradient-to-t from-[rgba(255,255,255,0.8)] to-[rgba(255,255,255,0)] animate-pulse"
                  : ""
              }`}
            />
            <div className="absolute w-full content-none -bottom-[60px] group-hover:bottom-0 left-0 text-white bg-zinc-500/50 px-4 py-3 ease-in-out duration-500">
              <h6 className="text-xl font-taviraj ">
                {sevenCategoriesValue[4]?.title.toUpperCase()}
              </h6>
              <p className="mt-1 hidden md:block font-dm">
                {sevenCategoriesValue[4]?.description}
              </p>
              <button
                onClick={() => setModalActive(true)}
                className="bg-[#50B3C1] text-sm sm:text-base hover:bg-[#419eab] border border-[#50B3C1] hover:border-white ease-in-out duration-500 rounded mt-4 py-2 sm:px-8 px-2"
              >
                {t("sendQuery")}
              </button>
            </div>
          </div>
          {/* 4 изображение из 7 продуктов */}

          <div className="aspect-h-1 aspect-w-1 bg-indigo-500 col-span-4 row-span-2 lg:col-span-3 lg:row-span-7 relative overflow-hidden group bg-[#efefefab]">
            <img
              data-aos="zoom-out"
              data-aos-once="true"

              loading="lazy"
              src={sevenCategoriesValue[6]?.image}
              alt="Buz Group Category Product"
              className={`h-full w-full object-cover group-hover:scale-105 transition-transform duration-700 ease-out ${
                !sevenCategoriesValue[6]?.image
                  ? "bg-gradient-to-t from-[rgba(255,255,255,0.8)] to-[rgba(255,255,255,0)] animate-pulse"
                  : ""
              }`}
            />
            <div className="absolute w-full content-none -bottom-[60px] group-hover:bottom-0 left-0 text-white bg-zinc-500/50 px-4 py-3 ease-in-out duration-500">
              <h6 className="text-xl font-taviraj ">
                {sevenCategoriesValue[6]?.title.toUpperCase()}
              </h6>
              <p className="mt-1 hidden md:block font-dm">
                {sevenCategoriesValue[6]?.description}
              </p>
              <button
                onClick={() => setModalActive(true)}
                className="bg-[#50B3C1] text-sm sm:text-base hover:bg-[#419eab] border border-[#50B3C1] hover:border-white ease-in-out duration-500 rounded mt-4 py-2 sm:px-8 px-2"
              >
                {t("sendQuery")}
              </button>
            </div>
          </div>

          {/* 5 изображение из 7 продуктов */}

          <div className="aspect-h-1 aspect-w-1 bg-indigo-500 col-span-4 row-span-2 lg:col-span-4 lg:row-span-9 relative overflow-hidden group bg-[#efefefab]">
            <img
              data-aos="zoom-out"
              data-aos-once="true"

              loading="lazy"
              src={sevenCategoriesValue[5]?.image}
              alt="Buz Group Category Product"
              className={`h-full w-full object-cover group-hover:scale-105 transition-transform duration-700 ease-out ${
                !sevenCategoriesValue[5]?.image
                  ? "bg-gradient-to-t from-[rgba(255,255,255,0.8)] to-[rgba(255,255,255,0)] animate-pulse"
                  : ""
              }`}
            />
            <div className="absolute w-full content-none -bottom-[60px] group-hover:bottom-0 left-0 text-white bg-zinc-500/50 px-4 py-3 ease-in-out duration-500">
              <h6 className="text-xl font-taviraj ">
                {sevenCategoriesValue[5]?.title.toUpperCase()}
              </h6>
              <p className="mt-1 hidden md:block font-dm">
                {sevenCategoriesValue[5]?.description}
              </p>
              <button
                onClick={() => setModalActive(true)}
                className="bg-[#50B3C1] text-sm sm:text-base hover:bg-[#419eab] border border-[#50B3C1] hover:border-white ease-in-out duration-500 rounded mt-4 py-2 sm:px-8 px-2"
              >
                {t("sendQuery")}
              </button>
            </div>
          </div>

          {/* 6 изображение из 7 продуктов */}

          <div className="aspect-h-1 aspect-w-1 bg-indigo-500 col-span-3 row-span-2 lg:col-span-2 lg:row-span-4 relative overflow-hidden group bg-[#efefefab]">
            <img
              data-aos="zoom-out"
              data-aos-once="true"

              loading="lazy"
              src={sevenCategoriesValue[2]?.image}
              alt="Buz Group Category Product"
              className={`h-full w-full object-cover group-hover:scale-105 transition-transform duration-700 ease-out ${
                !sevenCategoriesValue[2]?.image
                  ? "bg-gradient-to-t from-[rgba(255,255,255,0.8)] to-[rgba(255,255,255,0)] animate-pulse"
                  : ""
              }`}
            />
            <div className="absolute w-full content-none -bottom-[60px] group-hover:bottom-0 left-0 text-white bg-zinc-500/50 px-4 py-3 ease-in-out duration-500">
              <h6 className="text-xl font-taviraj ">
                {sevenCategoriesValue[2]?.title.toUpperCase()}
              </h6>
              <p className="mt-1 hidden md:block font-dm">
                {sevenCategoriesValue[2]?.description}
              </p>
              <button
                onClick={() => setModalActive(true)}
                className="bg-[#50B3C1] text-sm sm:text-base hover:bg-[#419eab] border border-[#50B3C1] hover:border-white ease-in-out duration-500 rounded mt-4 py-2 sm:px-8 px-2"
              >
                {t("sendQuery")}
              </button>
            </div>
          </div>

          {/* 7 изображение из 7 продуктов */}

          <div className="aspect-h-1 aspect-w-1 bg-indigo-500 col-span-7 row-span-2 lg:col-span-5 lg:row-span-5 relative overflow-hidden group bg-[#efefefab]">
            <img
              data-aos="zoom-out"
              data-aos-once="true"
              data-aos-offset="10"

              loading="lazy"
              src={sevenCategoriesValue[0]?.image}
              alt="Buz Group Category Product"
              className={`h-full w-full object-cover group-hover:scale-105 transition-transform duration-700 ease-out ${
                !sevenCategoriesValue[0]?.image
                  ? "bg-gradient-to-t from-[rgba(255,255,255,0.8)] to-[rgba(255,255,255,0)] animate-pulse"
                  : ""
              }`}
            />
            <div className="absolute w-full content-none -bottom-[60px] group-hover:bottom-0 left-0 text-white bg-zinc-500/50 px-4 py-3 ease-in-out duration-500">
              <h6 className="text-xl font-taviraj ">
                {sevenCategoriesValue[0]?.title.toUpperCase()}{" "}
              </h6>
              <p className="mt-1 hidden md:block font-dm">
                {sevenCategoriesValue[0]?.description}
              </p>
              <button
                onClick={() => setModalActive(true)}
                className="bg-[#50B3C1] text-sm sm:text-base hover:bg-[#419eab] border border-[#50B3C1] hover:border-white ease-in-out duration-500 rounded mt-4 py-2 sm:px-8 px-2"
              >
                {t("sendQuery")}
              </button>
            </div>
          </div>
        </div>

        {/* <SingleProdModal  open={openModal} onClose={() => setModalActive(false)} /> */}
        <SingleProdModal active={modalActive} setActive={setModalActive} />
      </div>
    </>
  );
};

export default HomeGrids;
