import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../images/logo.png";
import logo2 from "../images/logo2.png";
import { GrFormSearch } from "react-icons/gr";
import MobileNav from "./MobileNav";
import LanguageDropdown from "./LanguageDropdown";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import axios from "axios";
import HomeResSearchModal from "./HomeResSearchModal";
// import ParticlesBackground from '../components/common/Sparticles/ParticlesBackground'




const Navbar = () => {
  const { t } = useTranslation();
  // Используем код языка для отображения url www.site.com/en/...
  const lang = i18next?.language.toLowerCase();

  const [search, setSearch] = useState("");

  const [results, setResults] = useState([]);
 
  // Функция для обновления значения поиска
  const changeSearch = (value) => {
    setSearch(value);
  };

  const [isLoading, setIsLoading] = useState(false)

  // const isValid = /^[a-zA-Z0-9\s]*$/.test(search); - Использовать при необходимости для alert для првильного ввода
  useEffect(() => {
    setIsLoading(true)
    if (search.length >= 3) {
      const fetchData = async () => {
        try {
          const response = await axios.get(
            `https://buzgroupapi-eyb7ffhde4hvh4ex.germanywestcentral-01.azurewebsites.net/api/search/?query=${search}`
          );
          setResults(response.data); 
          setIsLoading(false)
        } catch (error) {
          console.error(error);
        }
      };
      fetchData();
    }
   
    // Проверяем длину строки, чтобы отправить запрос только если длина больше или равна 3 символам
  }, [search]); 

  
// Эффект при прокрутке 

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    // Функция для отслеживания прокрутки
    const handleScroll = () => {
      if (window.scrollY > 50) { // если прокрутка больше 50px
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    // Добавление события прокрутки
    window.addEventListener("scroll", handleScroll);

    // Очистка события при размонтировании компонента
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  
  return (
     <header
      className={`sticky top-0 z-30 transition-all duration-300 ease-in-out ${
        isScrolled ? "bg-[#000000ad] fixed z-[99999]" : "bg-[#000000]"
      }`}
    >
      {/* <ParticlesBackground /> */}
      <div className="max-w-[1440px] py-2 m-auto px-5 flex justify-between items-center gap-x-10">
        <div className="h-16 w-40">
          <Link to="/">
            <img src={isScrolled ? logo:logo2} alt="" className="h-12 mt-2 sm:mt-0 sm:h-16" style={{ maxWidth: 'none' }} />
          </Link>
        </div>
        <div className="max-w-[1100px] w-full lg:flex flex-col gap-y-4 hidden">
          <nav className="flex justify-end items-center">
            <ul className="flex gap-x-20 text-sm text-[#adadad] font-dm">
              <li className="cursor-pointer">
                <Link to={`${lang}/about`}>{t("aboutUs")}</Link>
              </li>
              <li className="cursor-pointer">
                <Link to={`${lang}/partners`}>{t("partners")}</Link>
              </li>
              <li className="cursor-pointer">
                <Link to={`${lang}/contact`}>{t("contact")}</Link>
              </li>
            </ul> 
            <LanguageDropdown />
          </nav>
          <div className="flex items-center justify-end">
            <ul className="flex items-center lg:gap-x-16 xl:gap-x-36  font-taviraj home_menu ">
              <li className="relative text-[#141130] group p-2 cursor-pointer">
                <Link to="/">{t("home")}</Link>
                <span className="absolute left-0 bottom-0 w-0 h-[3px] bg-[#50B3C1] transition-all duration-500 group-hover:w-full"></span>
              </li>
              {/* <li>
                <div className="relative text-[#141130] group p-2 cursor-pointer">
                  {t("services")}
                  <span className="absolute left-0 bottom-0 w-0 h-[3px] bg-[#50B3C1] transition-all duration-500 group-hover:w-full"></span>
                </div>
              </li> */}
              <li>
                <div className="relative text-[#141130] text-left cursor-pointer group">
                  <div>
                    <div className="flex w-full justify-center items-center gap-x-1.5 px-3 py-2">
                      <Link to={`${lang}/products`}>{t("products")}</Link>
                      {/* <IoMdArrowDropdown className="-mr-1 h-5 w-5 text-[#cbc4c3]"/> */}
                    </div>
                    <span className="absolute left-0 bottom-0 w-0 h-[3px] bg-[#50B3C1] transition-all duration-500 group-hover:w-full"></span>
                  </div>
                  {/* При наведении открывается нижная колонка со списком */}
                  {/* <div className="absolute right-0 z-10 pt-3 w-56 origin-top-right rounded-md bg-white shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-opacity duration-500" >
                                    <ul className="py-1" role="none">
                                    <li className="px-4 py-2 text-sm text-gray-700">Telefonlar</li>
                                    </ul>
                                </div> */}
                </div>
              </li>
              <li>
                <div className="relative text-[#141130] group p-2 cursor-pointer">
                  <Link to={`${lang}/news`}>{t("news")}</Link>
                  <span className="absolute left-0 bottom-0 w-0 h-[3px] bg-[#50B3C1] transition-all duration-500 group-hover:w-full"></span>
                </div>
              </li>
            </ul>
            {/* Search at home page config */}
            <div
              className="md:max-w-[356px] xl:max-w-[392px] w-full flex border rounded ml-8 xl:ml-16 relative"
            >
              <input
                type="search"
                value={search}
                onChange={(e) => changeSearch(e.target.value)}
                placeholder={t("productsServicesAndMore")}
                className="md:max-w-[356px] w-full border-none outline-none px-2 rounded-l bg-[#60606099] text-white"
              />
              <div className="bg-[#50B3C1] w-9 h-9 rounded-r">
                <GrFormSearch size={35} className="text-white" />
              </div>

                    {/*Открытие модалного окна при поиске   */}
              {search?.length>=3 && <HomeResSearchModal data={results} loading={isLoading} setSearch={setSearch}  />}
            </div>
          </div>
        </div>
        <MobileNav />
      </div>
    </header>
  );
};

export default Navbar;
