import React, { useRef } from "react";
import { GrFormAdd, GrFormSubtract } from "react-icons/gr";

const Accordion = ({
  item,
  isOpen,
  onClickAccordion,
  callCategoryIDlist,
  callSubCategoryIDlist,
}) => {
  const itemRef = useRef();

  return (
    <li className="border border-x-0 border-t-0 border-[#eaeaea] ">
      <div
        className="w-full flex justify-between items-center py-2 pl-4 pr-1 bg-gray-100 hover:bg-gray-200"
        style={{
          fontWeight: isOpen ? "800" : "",
          color: isOpen ? "rgb(80 179 193" : "",
        }}
      >
        {/* Нажатие на название категории */}
        <span
          className="flex-1 cursor-pointer transition-transform duration-300 ease-in-out hover:translate-x-2 group-hover:bg-indigo-500"
          onClick={() => {
            if (item.subcategories?.length > 0) {
              onClickAccordion(item.id); // Открыть или закрыть аккордеон
            } else {
              onClickAccordion(null); // Закрываем все аккордионы перед вызовом списка товаров
              callCategoryIDlist(item.id); // Вызов списка товаров
            }
          }}
        >
          {item.title}
        </span>

        {/* Отдельный обработчик для значка */}
        {item.subcategories?.length > 0 && (
          <button
            className="ml-2 flex items-center justify-center rounded hover:text-[#000f24] hover:bg-zinc-200 ease duration-300"
            onClick={() => onClickAccordion(item.id)}
          >
            {isOpen ? <GrFormSubtract size={24} /> : <GrFormAdd size={24} />}
          </button>
        )}
      </div>

      <div
        className="h-0 overflow-hidden transition-all duration-300"
        style={{
          height: isOpen ? `${itemRef.current.scrollHeight}px` : "0px",
        }}
      >
        <div className="px-2 bg-gray-50" ref={itemRef}>
          {item?.subcategories && (
            <ul className="pl-6">
              {item.subcategories.map((subItem) => (
                <li
                  key={subItem.id}
                  className="px-2 py-0.5 border border-x-0 border-b-0 border-[#eaeaea] transition ease-in-out duration-300 hover:translate-x-2 hover:bg-indigo-500"
                  onClick={() => callSubCategoryIDlist(subItem.id)}
                >
                  {subItem.title}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </li>
  );
};

export default Accordion;
