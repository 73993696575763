import React, { useEffect } from "react";
import Hero from "../components/Hero";
import HomeGrids from "../components/HomeGrids";
import NewsHome from "../components/NewsHome";
import PartnersSlider from "../components/PartnersSlider";
import { Helmet } from "react-helmet-async";
// import ParticlesBackground from '../components/common/Sparticles/ParticlesBackground'

import rightVector from "../images/rightVector.svg";
import leftVector from "../images/leftVector.svg";
import img from "../images/logo.png";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    // Дополнительные действия, если необходимо, после загрузки переводов
  }, [i18n.language]); // срабатывает при смене языка

  return (
    <>
      <Helmet>
        <title>{t("homeTit")}</title>
        <meta name="keywords" content={t("keywords")} />
        <meta name="description" content={t("description")} />

        <meta property="og:locale" content={i18n.language.replace("-", "_")} />
        <meta property="og:site_name" content="BUZ-GROUP.COM" />
        <meta property="og:title" content={t("ogTitle")} />
        <meta property="og:description" content={t("ogDescription")} />
        <meta property="og:image" content={img} />
        <meta property="og:image:width" content="600" />
        <meta property="og:image:height" content="315" />
        <meta property="og:url" content="https://buz-group.com" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={t("twitterTitle")} />
        <meta name="twitter:description" content={t("twitterDescription")} />
        <meta name="twitter:image" content={img} />
      </Helmet>
      <div className="relative overflow-hidden">
        <div className="absolute top-[1280px] -z-10 hidden sm:block sm:-right-36 lg:right-0">
          <img src={rightVector} alt="" />
        </div>
        <div className="absolute top-[1280px] -z-10 hidden sm:block sm:-left-36 lg:left-0">
          <img src={leftVector} alt="" />
        </div>

        {/* <ParticlesBackground/> */}
        <Hero />
        <HomeGrids />
        <PartnersSlider />
        <NewsHome />
      </div>
    </>
  );
};

export default Home;
