import { useEffect, useState } from "react";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { useDispatch, useSelector } from "react-redux";
import { getContacts } from "../action/MainAction";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import Loading from "./Loading";
import axios from "axios";
import Swal from "sweetalert2";
import { isLoadingStart } from "../redux/MainReducer";
import rightVector from "../images/rightVector.svg";
import leftVector from "../images/leftVector.svg";

const Contact = () => {
  const [inpName, setInpName] = useState("");
  const [inpLastName, setInpLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [emailInp, setEmailInp] = useState("");
  const [text, setText] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const nameChange = (e) => {
    setInpName(e.target.value);
  };
  const lastNameChange = (e) => {
    setInpLastName(e.target.value);
  };
  const phoneChange = (value) => {
    setPhone(value);
  };
  const emailChange = (e) => {
    setEmailInp(e.target.value);
  };
  const textChange = (e) => {
    setText(e.target.value);
  };
  const checkboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const dispatch = useDispatch();

  const { t } = useTranslation();

  // Вызов переменной содержащий данные о контактов
  const contactsValue = useSelector((state) => state.Data.contactsValue);

  // Вызов api/contacts/az/
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(isLoadingStart());
    const currLang =
      i18next.language !== "dev" ? i18next.language.toLowerCase() : "en";
    if (i18next.language !== "dev") {
      dispatch(getContacts(currLang));
    }
  }, [dispatch]);

  const loading = useSelector((state) => state.Data.loading);

  const [isLoading, setIsLoading] = useState(false);

  // Отправка данных на сервер при клике на send
  const sendMessage = (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!isChecked) {
      Swal.fire("Please check the box!");
    } else {
      const fetchData = async () => {
        const data = {
          email: emailInp,
          comment: text,
          first_name: inpName,
          last_name: inpLastName,
          phone_number: phone,
        };

        try {
          const response = await axios.post(
            "https://buzgroupapi-eyb7ffhde4hvh4ex.germanywestcentral-01.azurewebsites.net/api/send-client-email/",
            data, // passing data in request
            {
              headers: {
                "Content-Type": "application/json", // specify that we are sending JSON
              },
            }
          );
          if (response.status === 200) {
            setIsLoading(false);
            Swal.fire("Success!", "Message sent successfully!", "success");

            setInpName("");
            setInpLastName("");
            setPhone("");
            setEmailInp("");
            setText("");
            setIsChecked(false);
          }

          // Handle server response
        } catch (error) {
          Swal.fire(
            "Error",
            "Something went wrong, please try again.",
            "error"
          );
          console.error(error);
        }
      };
      fetchData();
    }
  };

  return (
    <div className="relative overflow-hidden">
      <div className="absolute top-0 -z-10 hidden sm:block sm:-right-36 lg:right-0">
        <img src={rightVector} alt="" />
      </div>
      <div className="absolute top-0 -z-10 hidden sm:block sm:-left-36 lg:left-0">
        <img src={leftVector} alt="" />
      </div>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <div className="bg-gradient-to-b from-[#FFFFFF]/40 to-[#A6A6A6]/10 py-[82px]">
            <div className="max-w-[1200px] mx-auto text-white">
              <h3 className="text-center text-[32px] font-taviraj">
                {t("contactUs")}
              </h3>

              <div className="mt-[36px] sm:mt-[50px] grid sm:grid-cols-2 lg:grid-cols-3 gap-y-6  sm:gap-y-10">
                <div>
                  <div className="px-3">
                    <div className="flex items-center gap-x-2">
                      <svg
                        className="mb-0.5"
                        width="13"
                        height="16"
                        viewBox="0 0 13 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.5 0.5C3.37402 0.5 0.875 2.99902 0.875 6.125C0.875 7.20605 1.19727 8.04102 1.75684 8.8877L5.9082 15.1865C6.03418 15.377 6.25098 15.5 6.5 15.5C6.74902 15.5 6.96875 15.374 7.0918 15.1865L11.2432 8.8877C11.8027 8.04102 12.125 7.20605 12.125 6.125C12.125 2.99902 9.62598 0.5 6.5 0.5ZM6.5 9.24805C4.77441 9.24805 3.37402 7.84766 3.37402 6.11914C3.37402 4.39062 4.77441 2.99023 6.5 2.99023C8.22559 2.99023 9.62598 4.39062 9.62598 6.11914C9.62598 7.84766 8.22559 9.24805 6.5 9.24805ZM6.5 4.25C5.46289 4.25 4.625 5.08789 4.625 6.125C4.625 7.16211 5.46289 8 6.5 8C7.53711 8 8.375 7.16211 8.375 6.125C8.375 5.08789 7.53711 4.25 6.5 4.25Z"
                          fill="white"
                        />
                      </svg>
                      <h6 className="text-lg sm:text-xl font-taviraj font-bold py-2">
                        Baku office
                      </h6>
                    </div>
                    {contactsValue[0]?.addresses.map((data, i) => (
                      <p
                        key={i}
                        className="text-sm sm:text-base font-dm px-6 leading-5 sm:leading-7"
                      >
                        {data}
                      </p>
                    ))}
                  </div>
                  <div className="px-3">
                    <div className="flex items-start sm:items-center gap-x-2 px-6 py-2 sm:py-3">
                      <svg
                        className="mt-1"
                        width="15"
                        height="16"
                        viewBox="0 0 15 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.83084 1.22133C4.60527 0.676434 4.01057 0.386408 3.44223 0.541674L0.86422 1.24477C0.354477 1.38539 0 1.84826 0 2.37558C0 9.62331 5.87669 15.5 13.1244 15.5C13.6517 15.5 14.1146 15.1455 14.2552 14.6358L14.9583 12.0578C15.1136 11.4894 14.8236 10.8947 14.2787 10.6692L11.4663 9.49733C10.9888 9.29813 10.4351 9.43581 10.1099 9.83716L8.92636 11.2814C6.86396 10.3059 5.19411 8.63604 4.21856 6.57364L5.66284 5.39302C6.06419 5.06491 6.20187 4.51416 6.00266 4.03664L4.83084 1.22426V1.22133Z"
                          fill="white"
                        />
                      </svg>
                      <div className="flex flex-wrap gap-x-3 text-sm sm:text-base">
                        {contactsValue[0]?.phone_numbers?.map((data, i) => (
                          <p key={i} className="font-dm">
                            {data}
                          </p>
                        ))}
                        {contactsValue[0]?.phone_numbers?.map((data, i) => (
                          <p key={i} className="font-dm">
                            {data}
                          </p>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="px-3">
                    <div className="flex items-center gap-x-2 px-6">
                      <svg
                        width="15"
                        height="16"
                        viewBox="0 0 15 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_519_12357)">
                          <path
                            d="M14.2209 2.3753C14.157 2.36872 14.0926 2.36872 14.0287 2.3753H0.903711C0.819591 2.3766 0.736028 2.38921 0.655273 2.4128L7.42871 9.15811L14.2209 2.3753Z"
                            fill="white"
                          />
                          <path
                            d="M14.9145 3.02686L8.08945 9.82373C7.9138 9.99834 7.67619 10.0963 7.42852 10.0963C7.18084 10.0963 6.94323 9.99834 6.76758 9.82373L0.00351565 3.07842C-0.0172784 3.15484 -0.0283037 3.2336 -0.0292969 3.31279V12.6878C-0.0292969 12.9364 0.0694752 13.1749 0.245291 13.3507C0.421106 13.5265 0.659563 13.6253 0.908203 13.6253H14.0332C14.2818 13.6253 14.5203 13.5265 14.6961 13.3507C14.8719 13.1749 14.9707 12.9364 14.9707 12.6878V3.31279C14.967 3.21513 14.948 3.11865 14.9145 3.02686ZM1.55039 12.6878H0.898828V12.0175L4.30664 8.63779L4.96758 9.29873L1.55039 12.6878ZM14.0238 12.6878H13.3676L9.95039 9.29873L10.6113 8.63779L14.0191 12.0175L14.0238 12.6878Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_519_12357">
                            <rect
                              width="15"
                              height="15"
                              fill="white"
                              transform="translate(0 0.5)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      {contactsValue[0]?.emails?.map((data, i) => (
                        <p key={i} className="text-sm sm:text-basefont-dm">
                          {data}
                        </p>
                      ))}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="px-3">
                    <div className="flex items-center gap-x-2">
                      <svg
                        className="mb-0.5"
                        width="13"
                        height="16"
                        viewBox="0 0 13 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.5 0.5C3.37402 0.5 0.875 2.99902 0.875 6.125C0.875 7.20605 1.19727 8.04102 1.75684 8.8877L5.9082 15.1865C6.03418 15.377 6.25098 15.5 6.5 15.5C6.74902 15.5 6.96875 15.374 7.0918 15.1865L11.2432 8.8877C11.8027 8.04102 12.125 7.20605 12.125 6.125C12.125 2.99902 9.62598 0.5 6.5 0.5ZM6.5 9.24805C4.77441 9.24805 3.37402 7.84766 3.37402 6.11914C3.37402 4.39062 4.77441 2.99023 6.5 2.99023C8.22559 2.99023 9.62598 4.39062 9.62598 6.11914C9.62598 7.84766 8.22559 9.24805 6.5 9.24805ZM6.5 4.25C5.46289 4.25 4.625 5.08789 4.625 6.125C4.625 7.16211 5.46289 8 6.5 8C7.53711 8 8.375 7.16211 8.375 6.125C8.375 5.08789 7.53711 4.25 6.5 4.25Z"
                          fill="white"
                        />
                      </svg>
                      <h6 className="text-lg sm:text-xl font-taviraj font-bold py-2">
                        Astana office
                      </h6>
                    </div>
                    {contactsValue[0]?.addresses.map((data, i) => (
                      <p
                        key={i}
                        className="text-sm sm:text-base font-dm px-6 leading-5 sm:leading-7"
                      >
                        {data}
                      </p>
                    ))}
                  </div>
                  <div className="px-3">
                    <div className="flex items-start sm:items-center gap-x-2 px-6 py-2 sm:py-3">
                      <svg
                        className="mt-1"
                        width="15"
                        height="16"
                        viewBox="0 0 15 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.83084 1.22133C4.60527 0.676434 4.01057 0.386408 3.44223 0.541674L0.86422 1.24477C0.354477 1.38539 0 1.84826 0 2.37558C0 9.62331 5.87669 15.5 13.1244 15.5C13.6517 15.5 14.1146 15.1455 14.2552 14.6358L14.9583 12.0578C15.1136 11.4894 14.8236 10.8947 14.2787 10.6692L11.4663 9.49733C10.9888 9.29813 10.4351 9.43581 10.1099 9.83716L8.92636 11.2814C6.86396 10.3059 5.19411 8.63604 4.21856 6.57364L5.66284 5.39302C6.06419 5.06491 6.20187 4.51416 6.00266 4.03664L4.83084 1.22426V1.22133Z"
                          fill="white"
                        />
                      </svg>
                      <div className="flex flex-wrap gap-x-3 text-sm sm:text-base">
                        {contactsValue[0]?.phone_numbers?.map((data, i) => (
                          <p key={i} className="font-dm">
                            {data}
                          </p>
                        ))}
                        {contactsValue[0]?.phone_numbers?.map((data, i) => (
                          <p key={i} className="font-dm">
                            {data}
                          </p>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="px-3">
                    <div className="flex items-center gap-x-2 px-6">
                      <svg
                        width="15"
                        height="16"
                        viewBox="0 0 15 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_519_12357)">
                          <path
                            d="M14.2209 2.3753C14.157 2.36872 14.0926 2.36872 14.0287 2.3753H0.903711C0.819591 2.3766 0.736028 2.38921 0.655273 2.4128L7.42871 9.15811L14.2209 2.3753Z"
                            fill="white"
                          />
                          <path
                            d="M14.9145 3.02686L8.08945 9.82373C7.9138 9.99834 7.67619 10.0963 7.42852 10.0963C7.18084 10.0963 6.94323 9.99834 6.76758 9.82373L0.00351565 3.07842C-0.0172784 3.15484 -0.0283037 3.2336 -0.0292969 3.31279V12.6878C-0.0292969 12.9364 0.0694752 13.1749 0.245291 13.3507C0.421106 13.5265 0.659563 13.6253 0.908203 13.6253H14.0332C14.2818 13.6253 14.5203 13.5265 14.6961 13.3507C14.8719 13.1749 14.9707 12.9364 14.9707 12.6878V3.31279C14.967 3.21513 14.948 3.11865 14.9145 3.02686ZM1.55039 12.6878H0.898828V12.0175L4.30664 8.63779L4.96758 9.29873L1.55039 12.6878ZM14.0238 12.6878H13.3676L9.95039 9.29873L10.6113 8.63779L14.0191 12.0175L14.0238 12.6878Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_519_12357">
                            <rect
                              width="15"
                              height="15"
                              fill="white"
                              transform="translate(0 0.5)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      {contactsValue[0]?.emails?.map((data, i) => (
                        <p key={i} className="text-sm sm:text-basefont-dm">
                          {data}
                        </p>
                      ))}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="px-3">
                    <div className="flex items-center gap-x-2">
                      <svg
                        className="mb-0.5"
                        width="13"
                        height="16"
                        viewBox="0 0 13 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.5 0.5C3.37402 0.5 0.875 2.99902 0.875 6.125C0.875 7.20605 1.19727 8.04102 1.75684 8.8877L5.9082 15.1865C6.03418 15.377 6.25098 15.5 6.5 15.5C6.74902 15.5 6.96875 15.374 7.0918 15.1865L11.2432 8.8877C11.8027 8.04102 12.125 7.20605 12.125 6.125C12.125 2.99902 9.62598 0.5 6.5 0.5ZM6.5 9.24805C4.77441 9.24805 3.37402 7.84766 3.37402 6.11914C3.37402 4.39062 4.77441 2.99023 6.5 2.99023C8.22559 2.99023 9.62598 4.39062 9.62598 6.11914C9.62598 7.84766 8.22559 9.24805 6.5 9.24805ZM6.5 4.25C5.46289 4.25 4.625 5.08789 4.625 6.125C4.625 7.16211 5.46289 8 6.5 8C7.53711 8 8.375 7.16211 8.375 6.125C8.375 5.08789 7.53711 4.25 6.5 4.25Z"
                          fill="white"
                        />
                      </svg>
                      <h6 className="text-lg sm:text-xl font-taviraj font-bold py-2">
                        Tbilisi office
                      </h6>
                    </div>
                    {contactsValue[0]?.addresses.map((data, i) => (
                      <p
                        key={i}
                        className="text-sm sm:text-base font-dm px-6 leading-5 sm:leading-7"
                      >
                        {data}
                      </p>
                    ))}
                  </div>
                  <div className="px-3">
                    <div className="flex items-start sm:items-center gap-x-2 px-6 py-2 sm:py-3">
                      <svg
                        className="mt-1"
                        width="15"
                        height="16"
                        viewBox="0 0 15 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.83084 1.22133C4.60527 0.676434 4.01057 0.386408 3.44223 0.541674L0.86422 1.24477C0.354477 1.38539 0 1.84826 0 2.37558C0 9.62331 5.87669 15.5 13.1244 15.5C13.6517 15.5 14.1146 15.1455 14.2552 14.6358L14.9583 12.0578C15.1136 11.4894 14.8236 10.8947 14.2787 10.6692L11.4663 9.49733C10.9888 9.29813 10.4351 9.43581 10.1099 9.83716L8.92636 11.2814C6.86396 10.3059 5.19411 8.63604 4.21856 6.57364L5.66284 5.39302C6.06419 5.06491 6.20187 4.51416 6.00266 4.03664L4.83084 1.22426V1.22133Z"
                          fill="white"
                        />
                      </svg>
                      <div className="flex flex-wrap gap-x-3 text-sm sm:text-base">
                        {contactsValue[0]?.phone_numbers?.map((data, i) => (
                          <p key={i} className="font-dm">
                            {data}
                          </p>
                        ))}
                        {contactsValue[0]?.phone_numbers?.map((data, i) => (
                          <p key={i} className="font-dm">
                            {data}
                          </p>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="px-3">
                    <div className="flex items-center gap-x-2 px-6">
                      <svg
                        width="15"
                        height="16"
                        viewBox="0 0 15 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_519_12357)">
                          <path
                            d="M14.2209 2.3753C14.157 2.36872 14.0926 2.36872 14.0287 2.3753H0.903711C0.819591 2.3766 0.736028 2.38921 0.655273 2.4128L7.42871 9.15811L14.2209 2.3753Z"
                            fill="white"
                          />
                          <path
                            d="M14.9145 3.02686L8.08945 9.82373C7.9138 9.99834 7.67619 10.0963 7.42852 10.0963C7.18084 10.0963 6.94323 9.99834 6.76758 9.82373L0.00351565 3.07842C-0.0172784 3.15484 -0.0283037 3.2336 -0.0292969 3.31279V12.6878C-0.0292969 12.9364 0.0694752 13.1749 0.245291 13.3507C0.421106 13.5265 0.659563 13.6253 0.908203 13.6253H14.0332C14.2818 13.6253 14.5203 13.5265 14.6961 13.3507C14.8719 13.1749 14.9707 12.9364 14.9707 12.6878V3.31279C14.967 3.21513 14.948 3.11865 14.9145 3.02686ZM1.55039 12.6878H0.898828V12.0175L4.30664 8.63779L4.96758 9.29873L1.55039 12.6878ZM14.0238 12.6878H13.3676L9.95039 9.29873L10.6113 8.63779L14.0191 12.0175L14.0238 12.6878Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_519_12357">
                            <rect
                              width="15"
                              height="15"
                              fill="white"
                              transform="translate(0 0.5)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      {contactsValue[0]?.emails?.map((data, i) => (
                        <p key={i} className="text-sm sm:text-basefont-dm">
                          {data}
                        </p>
                      ))}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="px-3">
                    <div className="flex items-center gap-x-2">
                      <svg
                        className="mb-0.5"
                        width="13"
                        height="16"
                        viewBox="0 0 13 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.5 0.5C3.37402 0.5 0.875 2.99902 0.875 6.125C0.875 7.20605 1.19727 8.04102 1.75684 8.8877L5.9082 15.1865C6.03418 15.377 6.25098 15.5 6.5 15.5C6.74902 15.5 6.96875 15.374 7.0918 15.1865L11.2432 8.8877C11.8027 8.04102 12.125 7.20605 12.125 6.125C12.125 2.99902 9.62598 0.5 6.5 0.5ZM6.5 9.24805C4.77441 9.24805 3.37402 7.84766 3.37402 6.11914C3.37402 4.39062 4.77441 2.99023 6.5 2.99023C8.22559 2.99023 9.62598 4.39062 9.62598 6.11914C9.62598 7.84766 8.22559 9.24805 6.5 9.24805ZM6.5 4.25C5.46289 4.25 4.625 5.08789 4.625 6.125C4.625 7.16211 5.46289 8 6.5 8C7.53711 8 8.375 7.16211 8.375 6.125C8.375 5.08789 7.53711 4.25 6.5 4.25Z"
                          fill="white"
                        />
                      </svg>
                      <h6 className="text-lg sm:text-xl font-taviraj font-bold py-2">
                        Istanbul office
                      </h6>
                    </div>
                    {contactsValue[0]?.addresses.map((data, i) => (
                      <p
                        key={i}
                        className="text-sm sm:text-base font-dm px-6 leading-5 sm:leading-7"
                      >
                        {data}
                      </p>
                    ))}
                  </div>
                  <div className="px-3">
                    <div className="flex items-start sm:items-center gap-x-2 px-6 py-2 sm:py-3">
                      <svg
                        className="mt-1"
                        width="15"
                        height="16"
                        viewBox="0 0 15 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.83084 1.22133C4.60527 0.676434 4.01057 0.386408 3.44223 0.541674L0.86422 1.24477C0.354477 1.38539 0 1.84826 0 2.37558C0 9.62331 5.87669 15.5 13.1244 15.5C13.6517 15.5 14.1146 15.1455 14.2552 14.6358L14.9583 12.0578C15.1136 11.4894 14.8236 10.8947 14.2787 10.6692L11.4663 9.49733C10.9888 9.29813 10.4351 9.43581 10.1099 9.83716L8.92636 11.2814C6.86396 10.3059 5.19411 8.63604 4.21856 6.57364L5.66284 5.39302C6.06419 5.06491 6.20187 4.51416 6.00266 4.03664L4.83084 1.22426V1.22133Z"
                          fill="white"
                        />
                      </svg>
                      <div className="flex flex-wrap gap-x-3 text-sm sm:text-base">
                        {contactsValue[0]?.phone_numbers?.map((data, i) => (
                          <p key={i} className="font-dm">
                            {data}
                          </p>
                        ))}
                        {contactsValue[0]?.phone_numbers?.map((data, i) => (
                          <p key={i} className="font-dm">
                            {data}
                          </p>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="px-3">
                    <div className="flex items-center gap-x-2 px-6">
                      <svg
                        width="15"
                        height="16"
                        viewBox="0 0 15 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_519_12357)">
                          <path
                            d="M14.2209 2.3753C14.157 2.36872 14.0926 2.36872 14.0287 2.3753H0.903711C0.819591 2.3766 0.736028 2.38921 0.655273 2.4128L7.42871 9.15811L14.2209 2.3753Z"
                            fill="white"
                          />
                          <path
                            d="M14.9145 3.02686L8.08945 9.82373C7.9138 9.99834 7.67619 10.0963 7.42852 10.0963C7.18084 10.0963 6.94323 9.99834 6.76758 9.82373L0.00351565 3.07842C-0.0172784 3.15484 -0.0283037 3.2336 -0.0292969 3.31279V12.6878C-0.0292969 12.9364 0.0694752 13.1749 0.245291 13.3507C0.421106 13.5265 0.659563 13.6253 0.908203 13.6253H14.0332C14.2818 13.6253 14.5203 13.5265 14.6961 13.3507C14.8719 13.1749 14.9707 12.9364 14.9707 12.6878V3.31279C14.967 3.21513 14.948 3.11865 14.9145 3.02686ZM1.55039 12.6878H0.898828V12.0175L4.30664 8.63779L4.96758 9.29873L1.55039 12.6878ZM14.0238 12.6878H13.3676L9.95039 9.29873L10.6113 8.63779L14.0191 12.0175L14.0238 12.6878Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_519_12357">
                            <rect
                              width="15"
                              height="15"
                              fill="white"
                              transform="translate(0 0.5)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      {contactsValue[0]?.emails?.map((data, i) => (
                        <p key={i} className="text-sm sm:text-basefont-dm">
                          {data}
                        </p>
                      ))}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="px-3">
                    <div className="flex items-center gap-x-2">
                      <svg
                        className="mb-0.5"
                        width="13"
                        height="16"
                        viewBox="0 0 13 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.5 0.5C3.37402 0.5 0.875 2.99902 0.875 6.125C0.875 7.20605 1.19727 8.04102 1.75684 8.8877L5.9082 15.1865C6.03418 15.377 6.25098 15.5 6.5 15.5C6.74902 15.5 6.96875 15.374 7.0918 15.1865L11.2432 8.8877C11.8027 8.04102 12.125 7.20605 12.125 6.125C12.125 2.99902 9.62598 0.5 6.5 0.5ZM6.5 9.24805C4.77441 9.24805 3.37402 7.84766 3.37402 6.11914C3.37402 4.39062 4.77441 2.99023 6.5 2.99023C8.22559 2.99023 9.62598 4.39062 9.62598 6.11914C9.62598 7.84766 8.22559 9.24805 6.5 9.24805ZM6.5 4.25C5.46289 4.25 4.625 5.08789 4.625 6.125C4.625 7.16211 5.46289 8 6.5 8C7.53711 8 8.375 7.16211 8.375 6.125C8.375 5.08789 7.53711 4.25 6.5 4.25Z"
                          fill="white"
                        />
                      </svg>
                      <h6 className="text-lg sm:text-xl font-taviraj font-bold py-2">
                        Taskent office
                      </h6>
                    </div>
                    {contactsValue[0]?.addresses.map((data, i) => (
                      <p
                        key={i}
                        className="text-sm sm:text-base font-dm px-6 leading-5 sm:leading-7"
                      >
                        {data}
                      </p>
                    ))}
                  </div>
                  <div className="px-3">
                    <div className="flex items-start sm:items-center gap-x-2 px-6 py-2 sm:py-3">
                      <svg
                        className="mt-1"
                        width="15"
                        height="16"
                        viewBox="0 0 15 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.83084 1.22133C4.60527 0.676434 4.01057 0.386408 3.44223 0.541674L0.86422 1.24477C0.354477 1.38539 0 1.84826 0 2.37558C0 9.62331 5.87669 15.5 13.1244 15.5C13.6517 15.5 14.1146 15.1455 14.2552 14.6358L14.9583 12.0578C15.1136 11.4894 14.8236 10.8947 14.2787 10.6692L11.4663 9.49733C10.9888 9.29813 10.4351 9.43581 10.1099 9.83716L8.92636 11.2814C6.86396 10.3059 5.19411 8.63604 4.21856 6.57364L5.66284 5.39302C6.06419 5.06491 6.20187 4.51416 6.00266 4.03664L4.83084 1.22426V1.22133Z"
                          fill="white"
                        />
                      </svg>
                      <div className="flex flex-wrap gap-x-3 text-sm sm:text-base">
                        {contactsValue[0]?.phone_numbers?.map((data, i) => (
                          <p key={i} className="font-dm">
                            {data}
                          </p>
                        ))}
                        {contactsValue[0]?.phone_numbers?.map((data, i) => (
                          <p key={i} className="font-dm">
                            {data}
                          </p>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="px-3">
                    <div className="flex items-center gap-x-2 px-6">
                      <svg
                        width="15"
                        height="16"
                        viewBox="0 0 15 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_519_12357)">
                          <path
                            d="M14.2209 2.3753C14.157 2.36872 14.0926 2.36872 14.0287 2.3753H0.903711C0.819591 2.3766 0.736028 2.38921 0.655273 2.4128L7.42871 9.15811L14.2209 2.3753Z"
                            fill="white"
                          />
                          <path
                            d="M14.9145 3.02686L8.08945 9.82373C7.9138 9.99834 7.67619 10.0963 7.42852 10.0963C7.18084 10.0963 6.94323 9.99834 6.76758 9.82373L0.00351565 3.07842C-0.0172784 3.15484 -0.0283037 3.2336 -0.0292969 3.31279V12.6878C-0.0292969 12.9364 0.0694752 13.1749 0.245291 13.3507C0.421106 13.5265 0.659563 13.6253 0.908203 13.6253H14.0332C14.2818 13.6253 14.5203 13.5265 14.6961 13.3507C14.8719 13.1749 14.9707 12.9364 14.9707 12.6878V3.31279C14.967 3.21513 14.948 3.11865 14.9145 3.02686ZM1.55039 12.6878H0.898828V12.0175L4.30664 8.63779L4.96758 9.29873L1.55039 12.6878ZM14.0238 12.6878H13.3676L9.95039 9.29873L10.6113 8.63779L14.0191 12.0175L14.0238 12.6878Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_519_12357">
                            <rect
                              width="15"
                              height="15"
                              fill="white"
                              transform="translate(0 0.5)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      {contactsValue[0]?.emails?.map((data, i) => (
                        <p key={i} className="text-sm sm:text-basefont-dm">
                          {data}
                        </p>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="max-w-[1240px] mx-auto flex flex-col lg:flex-row items-center lg:items-start justify-between my-16 px-5 gap-10">
            <div className="w-full sm:w-[580px] flex flex-col text-white">
              <h4 className="text-[28px] lg:text-[32px] font-taviraj">
                {t("anyQuestions")}
              </h4>
              <p className="text-base lg:text-xl mt-8 font-dm">
                {t("ifYouHaveQuestions")}
              </p>
            </div>
            {/* Анкеда заполнения вопросов */}
            <form
              onSubmit={sendMessage}
              className="w-full sm:w-[580px] flex flex-col items-start gap-y-6 text-white"
            >
              <div className="w-full flex flex-col sm:flex-row gap-6">
                <div className="w-full py-2 px-3 border border-x-0 border-t-0 border-[#e4e4e7]">
                  {/* Name input */}
                  <label htmlFor="name" className="flex items-center gap-x-0.5">
                    {t("name")}
                    <span>
                      <svg
                        width="8"
                        height="8"
                        viewBox="0 0 8 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.45455 7.40909L3.54545 4.90909L1.43182 6.25L0.886364 5.29545L3.11364 4.13636L0.886364 2.97727L1.43182 2.02273L3.54545 3.36364L3.45455 0.863636H4.54545L4.45455 3.36364L6.56818 2.02273L7.11364 2.97727L4.88636 4.13636L7.11364 5.29545L6.56818 6.25L4.45455 4.90909L4.54545 7.40909H3.45455Z"
                          fill="#F31260"
                        />
                      </svg>
                    </span>
                  </label>
                  <input
                    type="text"
                    id="name"
                    placeholder={t("name")}
                    className="w-full border-none outline-none bg-[#000F24] bg-transparent"
                    value={inpName}
                    onChange={nameChange}
                    required
                  />
                </div>
                <div className="w-full py-2 px-3 border border-x-0 border-t-0 border-[#e4e4e7]">
                  <label
                    htmlFor="lastname"
                    className="flex items-center gap-x-0.5"
                  >
                    {t("surname")}
                    <span>
                      <svg
                        width="8"
                        height="8"
                        viewBox="0 0 8 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.45455 7.40909L3.54545 4.90909L1.43182 6.25L0.886364 5.29545L3.11364 4.13636L0.886364 2.97727L1.43182 2.02273L3.54545 3.36364L3.45455 0.863636H4.54545L4.45455 3.36364L6.56818 2.02273L7.11364 2.97727L4.88636 4.13636L7.11364 5.29545L6.56818 6.25L4.45455 4.90909L4.54545 7.40909H3.45455Z"
                          fill="#F31260"
                        />
                      </svg>
                    </span>
                  </label>
                  {/* Lastname Input */}
                  <input
                    type="text"
                    id="lastname"
                    placeholder={t("surname")}
                    className="w-full border-none outline-none bg-[#000F24] bg-transparent"
                    value={inpLastName}
                    onChange={lastNameChange}
                    required
                  />
                </div>
              </div>
              <div className="w-full flex flex-col sm:flex-row  gap-6">
                <div className="w-full px-3 border border-x-0 border-t-0 border-[#e4e4e7]">
                  {/* Telefon input  */}
                  <div>
                    <label
                      htmlFor="phone"
                      className="flex items-center gap-x-0.5"
                    >
                      {t("phone")}
                      <span>
                        <svg
                          width="8"
                          height="8"
                          viewBox="0 0 8 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.45455 7.40909L3.54545 4.90909L1.43182 6.25L0.886364 5.29545L3.11364 4.13636L0.886364 2.97727L1.43182 2.02273L3.54545 3.36364L3.45455 0.863636H4.54545L4.45455 3.36364L6.56818 2.02273L7.11364 2.97727L4.88636 4.13636L7.11364 5.29545L6.56818 6.25L4.45455 4.90909L4.54545 7.40909H3.45455Z"
                            fill="#F31260"
                          />
                        </svg>
                      </span>
                    </label>
                    <PhoneInput
                      placeholder={t("phone")}
                      defaultCountry="az"
                      value={phone}
                      onChange={(phone) => phoneChange(phone)}
                      required
                    />
                  </div>
                </div>
                <div className="w-full py-2 px-3 border border-x-0 border-t-0 border-[#e4e4e7]">
                  <label
                    htmlFor="email"
                    className="flex items-center gap-x-0.5"
                  >
                    E-mail
                    <span>
                      <svg
                        width="8"
                        height="8"
                        viewBox="0 0 8 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.45455 7.40909L3.54545 4.90909L1.43182 6.25L0.886364 5.29545L3.11364 4.13636L0.886364 2.97727L1.43182 2.02273L3.54545 3.36364L3.45455 0.863636H4.54545L4.45455 3.36364L6.56818 2.02273L7.11364 2.97727L4.88636 4.13636L7.11364 5.29545L6.56818 6.25L4.45455 4.90909L4.54545 7.40909H3.45455Z"
                          fill="#F31260"
                        />
                      </svg>
                    </span>
                  </label>
                  <input
                    type="email"
                    id="email"
                    placeholder="..."
                    className="w-full border-none outline-none bg-[#000F24] bg-transparent"
                    value={emailInp}
                    onChange={emailChange}
                    required
                  />
                </div>
              </div>
              <div className="w-full">
                <label htmlFor="message" className="text-white px-3">
                  {t("messageBox")}
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows="7"
                  cols="50"
                  placeholder="Text..."
                  className="w-full py-1 px-3 outline-none border border-[#e4e4e7] resize-none bg-[#000F24] bg-transparent"
                  value={text}
                  onChange={textChange}
                  required
                ></textarea>
              </div>
              <div className="flex items-start mb-4">
                {/* Checkbox input */}
                <input
                  id="default-checkbox"
                  type="checkbox"
                  value={isChecked}
                  className="mt-1.5 text-white"
                  onChange={checkboxChange}
                />
                <label
                  htmlFor="default-checkbox"
                  className="max-w-[500px] ms-2 text-sm text-white leading-6"
                >
                  {t("emailConsent")}
                </label>
              </div>
              <button className="flex text-white font-dm text-sm rounded bg-lightGreen hover:bg-darkGreen border border-lightGreen hover:border-white ease-in-out duration-500 tracking-widest px-20 py-4 sm:px-24 lg:px-[136px] sm:py-5">
                {t("send")}
              </button>
            </form>
          </div>
          <div className="max-w-[1240px] mx-auto flex flex-col lg:flex-row items-center lg:items-start justify-between my-16 px-5 gap-10">
            <div className="w-full sm:w-[580px] flex flex-col text-white">
              <h4 className="text-[28px] lg:text-[32px] font-taviraj">
                {t("findUsHere")}
              </h4>
              <p className="text-base lg:text-xl mt-8 font-dm">
                {contactsValue[0]?.addresses[0]}
              </p>
            </div>
            <div className="max-w-[790px] w-full lg:w-[690px]">
              <iframe
                src={`https://www.google.com/maps?q=${encodeURIComponent(
                  contactsValue[0]?.addresses[0]
                )}&output=embed`}
                className="w-full h-[400px] border-none"
                allowFullScreen
                title="Google Maps location" // Добавлен атрибут title
              ></iframe>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Contact;
