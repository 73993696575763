import React from "react";
import News from "../components/News";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
// import ParticlesBackground from "../components/common/Sparticles/ParticlesBackground";
import img from "../images/logo.png";


const NewsPage = () => {

  const {t, i18n} = useTranslation()
  return (
    <>
      <Helmet>
        <title>{t("newsTit")}</title>
        <meta name="keywords" content={t("keywordsNews")} />
        <meta name="description" content={t("descriptionNews")} />

        <meta property="og:locale" content={i18n.language.replace("-", "_")} />
        <meta property="og:site_name" content="Buz-Group.com" />
        <meta property="og:title" content={t("ogTitleNews")} />
        <meta property="og:description" content={t("ogDescriptionNews")} />
        <meta property="og:image" content={img} />
        <meta property="og:image:width" content="600" />
        <meta property="og:image:height" content="315" />
        <meta property="og:url" content="https://buz-group.com/news" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={t("twitterTitleNews")} />
        <meta
          name="twitter:description"
          content={t("twitterDescriptionNews")}
        />
        <meta name="twitter:image" content={img} />
      </Helmet>

      {/* <ParticlesBackground /> */}
      <News />
    </>
  );
};

export default NewsPage;
