import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNewsID } from "../action/MainAction";
import { Link, useParams } from "react-router-dom";
import DOMPurify from "dompurify";
import { isLoadingStart } from "../redux/MainReducer";
import Loading from "./Loading";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import rightVector from "../images/rightVector.svg";
import leftVector from "../images/leftVector.svg";

const SingleNews = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Вызов api/blogs/id/az/ проверка наличия id, а потом отправка запроса
  useEffect(() => {
    window.scrollTo(0, 0); // Для показа страницы с головы
    dispatch(isLoadingStart());
    const currLang = i18next.language !== "dev" ? i18next.language.toLowerCase() : "en";
    if (id && i18next.language !== "dev") {
      dispatch(getNewsID(id, currLang));
    }
  }, [dispatch, id]);

  const newsIDvalue = useSelector((state) => state.Data.newsIDvalue);
  const loading = useSelector((state) => state.Data.loading);


  

  // Используем код языка для отображения url www.site.com/en/...
  const lang = i18next?.language.toLowerCase();

  return (
    <div className="relative overflow-hidden">
      <div className="absolute top-0 -z-10 hidden sm:block sm:-right-36 lg:right-0">
        <img src={rightVector} alt="" />
      </div>
      <div className="absolute top-0 -z-10 hidden sm:block sm:-left-36 lg:left-0">
        <img src={leftVector} alt="" />
      </div>

      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="max-w-[1248px] mx-auto px-6 my-24 md:mb-[8rem] lg:mb-[12rem] xl:mb-[18rem] text-white">
            <h4 className="text-2xl sm:text-3xl font-semibold font-taviraj">
              {newsIDvalue?.title}
            </h4>
            <div className="mt-8 flex flex-col sm:block">
              <div className="sm:max-w-[348px] md:max-w-[448px] lg:max-w-[548px] lg:h-[320px] float-right sm:ml-4">
                <img
                  src={newsIDvalue?.image}
                  alt={`BUZ GROUP NEWS ${newsIDvalue?.title}`}
                  className="w-full h-full"
                />
              </div>
              <p
                className="text-lg text-justify leading-8 font-dm mt-8 sm:mt-0"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(newsIDvalue?.content),
                }}
              ></p>
            </div>
          </div>
          <div className="bg-gradient-to-b from-[#FFFFFF]/40 to-[#A6A6A6]/10 py-14 md:py-24">
            <div className="max-w-[1240px] mx-auto text-white px-5">
              <h4 className="text-3xl font-semibold font-taviraj mb-10">
                {t("chooseUs")}
              </h4>
              <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-x-16 gap-y-7">
                <p className="text-xl max-w-[710px] leading-7 font-dm">
                  {t("collaborationBenefits")}
                </p>
                <Link to={`/${lang}/about`}>
                  <button className="text-[#50B3C1] hover:text-white border border-white bg-white hover:bg-[#50B3C1] duration-500 rounded px-10 py-3 lg:px-16 lg:py-5">
                    {t("aboutUs")}
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SingleNews;
