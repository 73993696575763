export const initialState = {
    // Пустое значение для карусел
    carouselImagesValue:[],
    partnersDataValue:[],
    bannersDataValue:[],
    sevenCategoriesValue:[],
    contactsValue:[],
    blogsHomePageValue:[],
    blogsAllNewsValue:[],
    newsIDvalue:[],
    catListValue:[],
    productsListValue:[],
    productIDvalue:[],
    mostSelListValue:[],
    homePagePartnersValue:[],
    // Loader value
    loading:true,
    brandListValue:[],

    aboutUsValue:[],
    partnersAboutValue:[]
}; 
