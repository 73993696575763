import i18next from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const HomeResSearchModal = ({ data, setSearch, loading }) => {
  const { t } = useTranslation();
  // Используем код языка для отображения url www.site.com/en/...

  const lang = i18next?.language.toLowerCase();
  return (
    <div className="search_list_modal absolute w-full h-[200px] bg-white top-[38px] lg:top-[44px] left-0 px-[5px] pb-[5px] rounded-b-[10px] lg:rounded-[10px]">
      {loading ? (
        <div className="search_loader">
          <span class="loader_search"></span>
        </div>
      ) : (
        <ul>
          {data?.length === 0 ? (
            <li className="search_list_style">{t("noResults")}</li>
          ) : (
            data?.map((data, i) => (
              <Link
                key={i}
                to={`/${lang}/singleprod/${data?.id}/${encodeURIComponent(
                  data?.title.replace(/\s+/g, "-").toLowerCase()
                )}`}
                onClick={() => setSearch("")}
              >
                <li className="ml-1 mt-1 text-gray hover:text-black text-sm sm:text-base hover:translate-x-1.5 cursor-pointer duration-300 border border-t-0 border-x-0 border-b-gray">
                  {data?.title}
                </li>
              </Link>
            ))
          )}
        </ul>
      )}
    </div>
  );
};

export default HomeResSearchModal;
