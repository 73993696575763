import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getBrandProList,
  getCategorybrands,
  getCategoryIDlist,
  getCatList,
  getPagProducts,
  getProductsList,
  getSUBCategorybrands,
  getSubCategoryIDlist,
  baseURL,
} from "../action/MainAction";
import ResponsivePagination from "react-responsive-pagination";

import { isLoadingStart } from "../redux/MainReducer";
import Loading from "./Loading";
import i18next from "i18next";
import Accordion from "./common/Accordion/Accordion";

import rightVector from "../images/rightVector.svg";
import leftVector from "../images/leftVector.svg";
import BrandsComponents from "./BrandsComponents";
import ProductListComponent from "./ProductListComponent";
import CategoryListMobVersion from "./CategoryListMobVersion";

const Products = () => {
  const dispatch = useDispatch();
 

  // Вызов переменной содеращий данные о партеров и пременная для loader
  const catListValue = useSelector((state) => state.Data.catListValue);

  // console.log(catListValue);

  const loading = useSelector((state) => state.Data.loading);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(isLoadingStart());

    // Вызов api/categories/az/
    const currLang =
      i18next.language !== "dev" ? i18next.language.toLowerCase() : "en";
    if (i18next.language !== "dev") {
      dispatch(getCatList(currLang));
    }
    // Вызов api/products/
    dispatch(getProductsList());
  }, [dispatch]);

  // Для toogle при нажатии на категорию при наличии подкатегории
  const [expandedCategories, setExpandedCategories] = useState({});

  const toggleSubcategories = (id) => {
    setExpandedCategories((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  // Вызов переменной содержащий данные о списке товаров
  const productsListValue = useSelector(
    (state) => state.Data.productsListValue
  );

  const brandListValue = useSelector((state) => state.Data.brandListValue);

  // console.log(productsListValue);

  // Orxan's code for burger menu
  const [catalog, setCatalog] = useState(false);

  const handleCatalog = () => {
    setCatalog(!catalog);
  };

  const [brandStatus, setBrandStatus] = useState(false);

  // Вызова списка товаров по категории
  const callCategoryIDlist = (id) => {
    setSelectedCategoryId(id);
    setSelectedSubCategoryId(null); // Сбрасываем подкатегорию
    dispatch(isLoadingStart());
    setCatalog(!catalog);
    dispatch(getCategoryIDlist(id));
    dispatch(getCategorybrands(id));
    setBrandStatus(true);
  };

  // Вызова списка товаров по ПОДкатегории
  const callSubCategoryIDlist = (id) => {
    setSelectedSubCategoryId(id);
    setSelectedCategoryId(null); // Сбрасываем категорию
    dispatch(isLoadingStart());
    setCatalog(!catalog);
    dispatch(getSubCategoryIDlist(id));
    dispatch(getSUBCategorybrands(id));
    setBrandStatus(true);
  };

  // Pagination count
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(productsListValue?.count / 12);

  // Смена показа продуктов при нажатии на числа пагинации
  const handlePageClick = async (page) => {
    // console.log(page);

    dispatch(isLoadingStart()); // Устанавливаем загрузку при нажатии на числа пагинации
    // console.log("Загрузка начата"); // Для теста отслеживания состояния

    window.scrollTo({ top: 0, behavior: "smooth" }); // Скроллим наверх
    setCurrentPage(page); // Устанавливаем текущую страницу

    // Формируем URL вручную, вместо использования previous/next
    let updatedUrl = `${baseURL}products/?page=${page}`; // Базовый URL

    if (selectedCategoryId) {
        updatedUrl = `${baseURL}products/category/${selectedCategoryId}/?page=${page}`;
    } else if (selectedSubCategoryId) {
        updatedUrl = `${baseURL}products/subcategory/${selectedSubCategoryId}/?page=${page}`;
    }

    dispatch(getPagProducts(updatedUrl)); // Один вызов dispatch
  };

  // Accordion test

  const [openId, setOpenId] = useState(null);

  const toggleCategory = (id) => {
    setOpenId((prevId) => (prevId === id ? null : id));
  };

  // Используем код языка для отображения url www.site.com/en/...
  const lang = i18next?.language.toLowerCase();

  // Состояние для слежки id каткгория или подкатегоия
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(null);

  // Вызов списка товаров при клике на названия бренда по категориям
  const brendClick = (brandId) => {
    const queryParams = new URLSearchParams();
    queryParams.append("brand", brandId);

    if (selectedCategoryId) {
      queryParams.append("category", selectedCategoryId);
    } else if (selectedSubCategoryId) {
      queryParams.append("subcategory", selectedSubCategoryId);
    }

    const url = `${baseURL}filter?${queryParams.toString()}`;

    dispatch(getBrandProList(url));
  };

  return (
    <div className="relative overflow-hidden">
      <div className="absolute top-0 -z-10 hidden sm:block sm:-right-36 lg:right-0">
        <img src={rightVector} alt="" />
      </div>
      <div className="absolute top-0 -z-10 hidden sm:block sm:-left-36 lg:left-0">
        <img src={leftVector} alt="" />
      </div>
      <div className="max-w-[1440px] mx-auto py-10 sm:py-24">
        {/* burger menu */}
        {
          <CategoryListMobVersion
            handleCatalog={handleCatalog}
            catalog={catalog}
            catListValue={catListValue}
            callCategoryIDlist={callCategoryIDlist}
            toggleSubcategories={toggleSubcategories}
            expandedCategories={expandedCategories}
            callSubCategoryIDlist={callSubCategoryIDlist}
          />
        }

        {/* Главная страница */}
        <div className="max-w-[580px] md:max-w-[1284px] font-dm text-white px-3 mx-auto flex justify-between md:gap-x-14">
          {/* Секция отображения категорий и подкатегорий */}
          <div data-aos="fade-right">
            <div className="md:w-[280px] lg:w-[320px] w-full hidden md:block">
              {/* Поле поиска */}
              {/* Временно не активное  */}
              {/* <div className="border border-x-0 border-t-0 border-[#eaeaea] bg-[#5f6b7c] flex justify-between items-center px-2">
                <input
                  type="text"
                  placeholder={t("keyword")}
                  className="w-full py-1 outline-none placeholder:text-white bg-[#5f6b7c] bg-bl px-2"
                />
                <div className="w-9 h-9 ml-1 rounded-r flex justify-center items-center cursor-pointer">
                  <GrFormSearch size={30} className="text-white" />
                </div>
              </div> */}

              {/* Список категорий */}
              <div className="categories">
                <ul className="cursor-pointer text-roboto">
                  {catListValue?.categories?.map((category) => (
                    <Accordion
                      key={category.id}
                      item={category}
                      isOpen={openId === category.id}
                      onClickAccordion={toggleCategory}
                      callCategoryIDlist={(id) => callCategoryIDlist(id)}
                      callSubCategoryIDlist={(id) => callSubCategoryIDlist(id)}
                    />
                  ))}
                </ul>
              </div>
            </div>
          </div>

          {/* Секция отображения товаров */}
          {loading ? (
            <Loading />
          ) : (
            <div>
              {/* Отображения название брендов и вызов списка товаров при нажатии на них */}
              <div
                className="brands_list"
                style={{
                  display:
                    !brandStatus || productsListValue.count === 0
                      ? "none"
                      : "flex",
                }}
              >
                {brandListValue?.map((data, i) => (
                  <BrandsComponents
                    key={i}
                    data={data}
                    brandStatus={brandStatus}
                    brendClick={brendClick}
                  />
                ))}
              </div>

              {/* Компонент для отображения списка товаров */}
              {
                <ProductListComponent
                  productsListValue={productsListValue}
                  lang={lang}
                />
              }

              {/* Pagination */}
              <ResponsivePagination
                current={currentPage}
                total={totalPages}
                onPageChange={(page) => handlePageClick(page)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Products;
