import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPartnersAbout, getPartnersData } from "../action/MainAction";
import Loading from "./Loading";
import { useTranslation } from "react-i18next";
import { isLoadingStart } from "../redux/MainReducer";
import i18next from "i18next";
import rightVector from "../images/rightVector.svg";
import leftVector from "../images/leftVector.svg";

const Partners = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const loading = useSelector((state) => state.Data.loading);

  // Вызов api/partners/
  useEffect(() => {
    dispatch(isLoadingStart());
    window.scrollTo(0, 0);

    dispatch(getPartnersData()); //Вызываем функцию для списка партнеров
    if (i18next.language !== "dev") {
      dispatch(getPartnersAbout(i18next.language.toLowerCase()));
    }
  }, [dispatch]);

  // Вызов переменной содержащий данные о партнерах
  const partnersAboutValue = useSelector(
    (state) => state.Data.partnersAboutValue
  );

  // Проверяем, что partnersAboutValue.partners является массивом
  const reversedPartners = Array.isArray(partnersAboutValue.partners)
    ? [...partnersAboutValue.partners].reverse()
    : [];

  return (
    <div className="relative overflow-hidden">
      <div className="absolute top-0 -z-10 hidden sm:block sm:-right-36 lg:right-0">
        <img src={rightVector} alt="" />
      </div>
      <div className="absolute top-0 -z-10 hidden sm:block sm:-left-36 lg:left-0">
        <img src={leftVector} alt="" />
      </div>
      {loading ? (
        <Loading />
      ) : (
        <div className="pt-[70px] pb-24">
          {/* <div data-aos="zoom-in"></div> */}
          <div
            data-aos="zoom-in"
            className="max-w-[934px] xl:max-w-[1224px] mx-auto px-3"
          >
            <h1 className="text-[32px] text-white font-taviraj">
              {t("corPart")}
            </h1>
            <p className="font-dm text-white mt-8 mb-20">
              {partnersAboutValue?.text}
            </p>
            <div className="bg-gradient-to-b from-[#FFFFFF]/40 to-[#A6A6A6]/10 rounded-lg p-8 xl:p-16 bg_gradient_540_screen">
              <div className="max-w-[1060px] mx-auto grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 place-items-center gap-x-3 gap-y-6 sm:gap-y-16 xl:gap-y-24">
                {reversedPartners?.map((data, i) => (
                  <div
                    key={i}
                    className="max-w-[244px] bg-white px-8 py-16 flex flex-col items-center justify-between gap-y-5 rounded-lg h-full"
                    style={{ backgroundColor: "rgb(0 0 0 / 29%)" }}
                  >
                    <div className="h-[100px] w-[150px] w_h_540_screen ">
                      <img
                        className="h-full w-full object-fill"
                        src={data?.image}
                        alt="BUZ GROUP partners"
                      />
                    </div>
                    <p className="text-lg font-bold text-white text-center ">
                      {data?.name}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Partners;
