import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import i18next from "i18next";
import { getAboutUs } from "../action/MainAction";
import Loading from "../components/Loading";
import { isLoadingStart } from "../redux/MainReducer";
import { Helmet } from "react-helmet-async";
// import ParticlesBackground from '../components/common/Sparticles/ParticlesBackground'
import rightVector from "../images/rightVector.svg";
import leftVector from "../images/leftVector.svg";
import img from "../images/logo.png";

const About = () => {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  // Вызов переменной содержащий данные о семи продуктах
  const aboutUsValue = useSelector((state) => state.Data.aboutUsValue);

  const loading = useSelector((state) => state.Data.loading);

  // Вызов api/about-us/language/
  useEffect(() => {
    dispatch(isLoadingStart());

    window.scrollTo(0, 0);

    const currLang =
      i18next.language !== "dev" ? i18next.language.toLowerCase() : "en";
    if (i18next.language !== "dev") {
      dispatch(getAboutUs(currLang));
    }
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>{t("aboutTit")}</title>
        <meta name="keywords" content={t("keywordsAbout")} />
        <meta name="description" content={t("descriptionAbout")} />

        <meta property="og:locale" content={i18n.language.replace("-", "_")} />
        <meta property="og:site_name" content="BUZ-GROUP.COM" />
        <meta property="og:title" content={t("ogTitleAbout")} />
        <meta property="og:description" content={t("ogDescriptionAbout")} />
        <meta property="og:image" content={img} />
        <meta property="og:image:width" content="600" />
        <meta property="og:image:height" content="315" />
        <meta property="og:url" content="https://buz-group.com" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={t("twitterTitleAbout")} />
        <meta name="twitter:description" content={t("twitterDescriptionAbout")} />
        <meta name="twitter:image" content={img} />
      </Helmet>
      <div className="relative overflow-hidden">
        <div className="absolute top-0 -z-10 hidden sm:block sm:-right-36 lg:right-0">
          <img src={rightVector} alt="" />
        </div>
        <div className="absolute top-0 -z-10 hidden sm:block sm:-left-36 lg:left-0">
          <img src={leftVector} alt="" />
        </div>

        {/* <ParticlesBackground/> */}

        {loading ? (
          <Loading />
        ) : (
          <div className="pt-[70px] text-white">
            {/* Head title */}
            <div className="max-w-[1248px] mx-auto px-6">
              <h3 className="text-[32px] font-taviraj">
                {aboutUsValue?.mission_title}
              </h3>
              <p className="mt-8 font-dm">{aboutUsValue?.mission_content}</p>
            </div>

            {/* Photo Company employees and work position  */}

            <div className="max-w-[1324px] mx-auto my-[60px] lg:my-[120px] ">
              <h3 className="max-w-[1248px] mx-auto text-[28px] px-6 font-taviraj">
                {t("professionalTeam")}
              </h3>
              <div className="grid grid-cols-2 sm:flex sm:flex-wrap justify-center sm:gap-x-[30px] gap-y-5 sm:gap-y-10 py-5">
                {aboutUsValue?.team_members?.map((data, i) => (
                  <div key={i} className=" max-w-[302px] px-8">
                    <div className="max-w-[240px] mb-8 rounded-full">
                      <img
                        src={data?.photo}
                        alt=""
                        className="w-full rounded-full hover:scale-105 duration-700"
                      />
                    </div>
                    <p className="text-center">{data?.name}</p>
                    <p className="text-center">{data?.role}</p>
                  </div>
                ))}
              </div>
            </div>

            <div className="w-full py-10 md:py-[70px] bg-gradient-to-b from-[#FFFFFF]/40 to-[#A6A6A6]/10">
              <div className="max-w-[1248px] mx-auto px-6 text-white flex flex-col lg:flex-row justify-between">
                <div className="w-full lg:max-w-[477px] p-6">
                  <h3 className="text-[32px] font-taviraj">
                    {aboutUsValue?.it_solutions_title}
                  </h3>
                  <p className="max-w-[700px] font-dm lg:max-w-[430px] leading-6 mt-6 mb-6 lg:mb-10">
                    {aboutUsValue?.it_solutions_content}
                  </p>
                  <button className="text-[#50B3C1] hover:text-white border border-white bg-white hover:bg-[#50B3C1] duration-500 rounded py-4 px-8 md:py-[22px] md:px-14">
                    {t("ourServices")}
                  </button>
                </div>

                <div className="h-[1px] max-w-[700px] lg:w-[1px] bg-white"></div>

                <div className="w-full lg:max-w-[477px] space-y-5 p-6">
                  <h3 className="text-[32px] font-taviraj">
                    {aboutUsValue?.tech_products_title}
                  </h3>
                  <p className="max-w-[700px] font-dm lg:max-w-[430px] leading-6">
                    {aboutUsValue?.tech_products_content}
                  </p>
                  <button className="text-[#50B3C1] hover:text-white border border-white bg-white hover:bg-[#50B3C1] duration-500 rounded py-4 px-8 md:py-[22px] md:px-14">
                    {t("ourProducts")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default About;
