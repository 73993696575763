import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { getHomePagePartners } from "../action/MainAction";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import i18next from "i18next";

const PartnersSlider = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  // Вызов переменной содержащий данные о партнеров
  const homePagePartnersValue = useSelector(
    (state) => state.Data.homePagePartnersValue
  );

  // Вызов api/partners/
  useEffect(() => {
    const currLang =
      i18next.language !== "dev" ? i18next.language.toLowerCase() : "en";
    if (i18next.language !== "dev") {
      dispatch(getHomePagePartners(currLang));
    }
  }, [i18next.language, dispatch]);

  // Используем код языка для отображения url www.site.com/en/...
  const lang = i18next?.language.toLowerCase();

  return (
    <div>
      {/* Content of Partners section */}

      <div className="max-w-[1248px] mx-auto flex flex-col justify-between items-start text-white pt-[70px] space-y-8 px-6">
        <h2 className="text-3xl md:text-4xl font-taviraj tracking-widest leading-[54px]">
          {t("partners")}
        </h2>

        <p className="text-base md:text-xl font-dm font-light leading-[30px]">
          {homePagePartnersValue?.text}
        </p>

        <Link to={`${lang}/partnerspage`}>
          <button className="text-sm font-dm py-5 px-12 bg-lightGreen hover:bg-darkGreen border border-lightGreen hover:border-white ease-in-out duration-300 tracking-widest rounded">
            {t("seeAll")}
          </button>
        </Link>
      </div>

      {/* Slider of Partners */}
      <div className="flex overflow-hidden mt-[70px] relative group">
        <div className="flex space-x-9 animate-loop-scroll group-hover:paused">
          <div className="flex space-x-9">
            {homePagePartnersValue?.partners?.map((image, i) => (
              <div
                className="min-w-[224px] max-w-[224px] max-h-[156px] bg-gradient-to-b from-[#FFFFFF]/40 to-[#A6A6A6]/10 backdrop-blur rounded-xl flex justify-center items-center"
                key={i}
              >
                <img
                  src={image?.image}
                  alt="BUZ GROUP partners"
                  className="w-[50%] h-[50%] object-contain"
                />
              </div>
            ))}
          </div>

          <div className="flex space-x-9" aria-hidden="true">
            {homePagePartnersValue.partners?.map((image, i) => (
              <div
                className="min-w-[224px] max-w-[224px] max-h-[156px] bg-gradient-to-b from-[#FFFFFF]/40 to-[#A6A6A6]/10 backdrop-blur rounded-xl flex justify-center items-center"
                key={`duplicate-${i}`}
              >
                <img
                  src={image?.image}
                  alt="BUZ GROUP partners"
                  className="w-[50%] h-[50%] object-contain"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnersSlider;
