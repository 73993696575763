import axios from "axios";
import {
  aboutUsFunc,
  bannersDataFunc,
  blogsHomePageFunc,
  brandList,
  // carouselImagesFunc,
  catListFunc,
  contactsFunc,
  getBlogsAllNewsFunc,
  homePagePartnersFunc,
  isLoadingFunc,
  mostSelListFunc,
  newsIDFunc,
  partenrsDataFunc,
  partersAboutFunc,
  productIDfunc,
  productsListFunc,
  sevenCategoriesFunc,
} from "../redux/MainReducer";

// Базовоя api
export const baseURL =
  "https://buzgroupapi-eyb7ffhde4hvh4ex.germanywestcentral-01.azurewebsites.net/api/";

// Отображения продуктов в самом начале страницы(BANERS)

export const getBannersData = () => async (dispatch) => {
  try {
    const response = await axios.get(baseURL + "banners/");
    dispatch(bannersDataFunc(response.data));
  } catch (error) {
    console.error(error);
    dispatch(bannersDataFunc([]));
  }
};

// Карусел на главной странице

// export const getCarouselImages = () => async (dispatch) => {
//   try {
//     const response = await axios.get(baseURL + "carousel-images/");
//     dispatch(carouselImagesFunc(response.data));
//     setTimeout(() => {
//       dispatch(isLoadingFunc());
//     }, 1000);
//   } catch (error) {
//     console.error(error);
//     dispatch(carouselImagesFunc([]));
//   }
// };

// Отображение фото партнеров и на главной странице и на собственной

export const getPartnersData = () => async (dispatch) => {
  try {
    const response = await axios.get(baseURL + "partners/az/");
    //    console.log(response?.data?.partners);

    dispatch(partenrsDataFunc(response?.data?.partners));
  } catch (error) {
    console.error(error);
    dispatch(partenrsDataFunc([]));
  }
};

// Отображения 7 продуктов по категориям на главной странице

export const getSevenCatecories = (currLang) => async (dispatch) => {
  if (currLang) {
    try {
      // Если язык 'kz' или 'uz', заменяем на 'en'
      const lang = ["kz", "uz"].includes(currLang) ? "en" : currLang;
      const response = await axios.get(
        `${baseURL}categories/homepage/${lang}/`
      );
      dispatch(sevenCategoriesFunc(response.data));
    } catch (error) {
      console.error(error);
      dispatch(sevenCategoriesFunc([]));
    }
  }
};

// Отображения контактов и на футоре и на отдельной странице контакты

export const getContacts = (currLang) => async (dispatch) => {
  if (currLang) {
    try {
      // Если язык 'kz' или 'uz', заменяем на 'en'
      const lang = ["kz", "uz"].includes(currLang) ? "en" : currLang;
      const response = await axios.get(`${baseURL}contacts/${lang}/`);
      // console.log(response);

      dispatch(contactsFunc(response.data));
    } catch (error) {
      console.log(error);
    }
  }
};

// Отображения новостей на главной странице

export const getBlogsHomePage = (currLang) => async (dispatch) => {
  if (currLang) {
    try {
      // Если язык 'kz' или 'uz', заменяем на 'en'
      const lang = ["kz", "uz"].includes(currLang) ? "en" : currLang;
      const response = await axios.get(`${baseURL}blogs/homepage/${lang}/`);
      // console.log(response.data);

      dispatch(blogsHomePageFunc(response.data));
    } catch (error) {
      console.error(error);
      dispatch(blogsHomePageFunc([]));
    }
  }
};

// Отображения новостей на странице новостей

export const getBlogsAllNews = (currLang) => async (dispatch) => {
  try {
    const lang = ["kz", "uz"].includes(currLang) ? "en" : currLang;
    const response = await axios.get(`${baseURL}blogs/${lang}/`);

    dispatch(getBlogsAllNewsFunc(response?.data));
    dispatch(isLoadingFunc());
  } catch (error) {
    console.error(error);
    dispatch(getBlogsAllNewsFunc([]));
  }
};

// Отображения новости на синг странице

export const getNewsID = (id, currLang) => async (dispatch) => {
  try {
    const lang = ["kz", "uz"].includes(currLang) ? "en" : currLang;
    const { data } = await axios.get(`${baseURL}blogs/${id}/${lang}/`);
    dispatch(newsIDFunc(data));
  } catch (error) {
    console.error("Error fetching news:", error);
    dispatch(isLoadingFunc());
  } finally {
    dispatch(isLoadingFunc());
  }
};

// Отображения списка категорий

export const getCatList = (currLang) => async (dispatch) => {
  if (currLang) {
    try {
      const lang = ["kz", "uz"].includes(currLang) ? "en" : currLang;
      const response = await axios.get(`${baseURL}categories/${lang}/`);

      dispatch(catListFunc(response.data));
    } catch (error) {
      console.log(error);
      dispatch(catListFunc([]));
    }
  }
};

// Отображения списка товаров

export const getProductsList = () => async (dispatch) => {
  try {
    const response = await axios.get(baseURL + "products/");
    dispatch(productsListFunc(response.data));
    dispatch(isLoadingFunc());
  } catch (error) {
    console.log(error);
    dispatch(isLoadingFunc());
  }
};

// Список товара по погинации на странице продукты
export const getPagProducts = (queryParams) => async (dispatch) => {
  
  if (!queryParams) return;

  try {
    // Если URL начинается с "http://", заменяем на "https://"
    const secureUrl = queryParams.startsWith("http://")
      ? queryParams.replace("http://", "https://")
      : queryParams;

    const response = await axios.get(secureUrl);

    dispatch(productsListFunc(response.data));
  } catch (error) {
    dispatch(isLoadingFunc());
  }
};

// Отображения списка товаров по категориям и используем productsListFunc снова
export const getCategoryIDlist = (id) => async (dispatch) => {
  if (id) {
    try {
      const response = await axios.get(`${baseURL}products/category/${id}`);
      dispatch(productsListFunc(response.data));
    } catch (error) {
      console.log(error);
    }
  }
};

// Отображения списка брендов
export const getCategorybrands = (id) => async (dispatch) => {
  if (id) {
    try {
      const response = await axios.get(`${baseURL}brands/category/${id}`);
      // console.log(response.data);

      dispatch(brandList(response.data));
    } catch (error) {
      console.log(error);
    }
  }
};
// Отображения списка брендов ПОДкатегорий
export const getSUBCategorybrands = (id) => async (dispatch) => {
  if (id) {
    try {
      const response = await axios.get(`${baseURL}brands/subcategory/${id}`);
      // console.log(response.data);

      dispatch(brandList(response.data));
    } catch (error) {
      console.log(error);
    }
  }
};

// Получаем список товара по брендам согласно категории
export const getBrandProList = (url) => async (dispatch) => {
  try {
    const response = await axios.get(url);
    // console.log(response.data);

    dispatch(productsListFunc(response.data));
  } catch (error) {
    console.log(error);
  }
};

// Отображения списка товаров по ПОДкатегориям и используем productsListFunc снова
export const getSubCategoryIDlist = (id) => async (dispatch) => {
  if (id) {
    try {
      const response = await axios.get(`${baseURL}products/subcategory/${id}/`);
      dispatch(productsListFunc(response.data));
    } catch (error) {
      console.log(error);
    }
  }
};

// Отображение данных товара

export const getProductID = (id, currLang) => async (dispatch) => {
  try {
    // деструктуризация объекта - автоматически извлекает data из ответа и сохраняет его в переменной data
    const lang = ["kz", "uz"].includes(currLang) ? "en" : currLang;
    const { data } = await axios.get(`${baseURL}products/${id}/${lang}/`);
    dispatch(productIDfunc(data));
  } catch (error) {
    console.error("Error fetching product:", error);
    dispatch(isLoadingFunc());
  } finally {
    dispatch(isLoadingFunc());
  }
};

// Отображение много продоваемых товаров

export const getMostSelList = () => async (dispatch) => {
  try {
    const response = await axios.get(baseURL + "most-selling-products/");
    dispatch(mostSelListFunc(response.data));
  } catch (error) {}
};

// Отображение инфо и работников компании
export const getAboutUs = (currLang) => async (dispatch) => {
  if (currLang) {
    try {
      const lang = ["kz", "uz"].includes(currLang) ? "en" : currLang;
      const response = await axios.get(`${baseURL}about-us/${lang}/`);
      // console.log(response.data);

      dispatch(aboutUsFunc(response.data));
      dispatch(isLoadingFunc());
    } catch (error) {
      console.log(error);
      dispatch(aboutUsFunc([]));
      dispatch(isLoadingFunc());
    }
  }
};

export const getPartnersAbout = (currLang) => async (dispatch) => {
  if (currLang) {
    try {
      const lang = ["kz", "uz"].includes(currLang) ? "en" : currLang;

      const response = await axios.get(`${baseURL}partners/${lang}/`);
      dispatch(partersAboutFunc(response.data));
      dispatch(isLoadingFunc());
    } catch (error) {
      console.error(error);
      dispatch(aboutUsFunc([]));
      dispatch(isLoadingFunc());
    }
  }
};

// Home page partners

export const getHomePagePartners = (currLang) => async (dispatch) => {
  try {
    // деструктуризация объекта - автоматически извлекает data из ответа и сохраняет его в переменной data
    const lang = ["kz", "uz"].includes(currLang) ? "en" : currLang;
    const { data } = await axios.get(`${baseURL}homepage/partners/${lang}/`);
    dispatch(homePagePartnersFunc(data));
  } catch (error) {
    console.error("Error fetching product:", error);
    dispatch(isLoadingFunc());
  } finally {
    dispatch(isLoadingFunc());
  }
};
