import React, { useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Navigation } from "swiper/modules";
import { useDispatch, useSelector } from "react-redux";
import { getBlogsHomePage } from "../action/MainAction";
import DOMPurify from "dompurify";
import { Link } from "react-router-dom";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

// import { IoMdArrowDropleft } from "react-icons/io";
// import { IoMdArrowDropright } from "react-icons/io";

const NewsHomeSlider = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Вызов переменной содержащий данные новости
  const blogsHomePageValue = useSelector(
    (state) => state.Data.blogsHomePageValue
  );

  // Вызов api/blogs/homepage/az/
  useEffect(() => {
    const currLang =
      i18next.language !== "dev" ? i18next.language.toLowerCase() : "en";
    if (i18next.language !== "dev") {
      dispatch(getBlogsHomePage(currLang));
    }
  }, [ i18next.language, dispatch]);

  // Используем код языка для отображения url www.site.com/en/...
  const lang = i18next?.language.toLowerCase();

  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        navigation={true}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        breakpoints={{
          580: {
            slidesPerView: 2,
          },
          1240: {
            slidesPerView: 3,
          },
          1660: {
            slidesPerView: 4,
          },
        }}
        modules={[Navigation, Autoplay]}
        className="newsSwiper my-12 mt-16 lg:my-20 lg:mt-24"
        // Остановка авто слайдера при наведении мышки
        onSwiper={(swiper) => {
          // Сохраняем экземпляр Swiper
          window.swiperInstance = swiper;
        }}
      >
        {blogsHomePageValue?.map((data, i) => (
          <SwiperSlide key={i}>
            <Link to={`${lang}/singlenewspage/${data?.id}`}>
              <div
                className="min-w-[300px] md:min-w-[350px] lg:min-w-[400px] h-[250px] lg:h-[300px] cursor-pointer relative group"
                onMouseEnter={() => window.swiperInstance?.autoplay.stop()} // ВЫКлючение авто слайдера при наведении мышки
                onMouseLeave={() => window.swiperInstance?.autoplay.start()} // ВКЛючение авто слайдера при отведении мышки
              >
                <img
                  src={data?.image}
                  alt="BUZ GROUP news title"
                  className="w-full h-full object-cover"
                />
                <div className="absolute w-full content-none -bottom-[28px] group-hover:bottom-0 left-0 ease-in-out duration-700 text-white bg-[#1b1b57]/40 px-3 py-1.5 lg:px-4 lg:py-2.5">
                  <h6 className="text-xl w-full font-taviraj">{data?.title}</h6>
                  <p
                    className="lg:mt-1 w-full font-dm"
                    dangerouslySetInnerHTML={{
                      __html:
                        DOMPurify.sanitize(data?.content).substring(0, 80) +
                        " . . .",
                    }}
                  ></p>
                  <button className="font-bold leading-4 font-dm mt-2">
                    {t("readMore")} →
                  </button>
                </div>
              </div>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default NewsHomeSlider;
