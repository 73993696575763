import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const ProductListComponent = React.memo(({ productsListValue, lang }) => {
  const { t } = useTranslation();

  const renderProducts = (products) =>
    products.map((data) => (
      <Link
        key={data.id}
        to={`/${lang}/singleprod/${data?.id}/${encodeURIComponent(
          data?.title.replace(/\s+/g, "-").toLowerCase()
        )}`}
        className=""
      >
        <div
          className="group flex flex-col items-center rounded-[10px] border-[#eaeaea] bg-gradient-to-b from-[#FFFFFF]/80 to-[#404040] 
          hover:bg-gradient-to-b hover:from-[#FFFFFF]/90 hover:to-[#404040] transition duration-700 ease-in-out product_card_style"
        >
          <div className="block left-auto">
            <div className="pb-6 mx-6 flex flex-col items-end relative">
              {data.is_new && data.is_discount ? (
                <div className="w-full flex justify-between items-center z-10 relative">
                  <div className="lg:text-xl text-center w-12 xs:w-14 md:w-[76px] rounded text-white bg-[#44d62c]">
                    NEW
                  </div>
                  <div className="lg:text-xl text-center w-12 xs:w-14 md:w-[76px] rounded text-white bg-[#5e2d7a]">
                    -{Math.floor(data.discount_amount)}%
                  </div>
                </div>
              ) : data.is_new || data.is_discount ? (
                <span
                  className={`lg:text-xl text-center w-12 xs:w-14 md:w-[76px] rounded text-white z-10 relative ${
                    data.is_new ? "bg-[#44d62c]" : "bg-[#5e2d7a]"
                  }`}
                >
                  {data.is_new
                    ? "NEW"
                    : `-${Math.floor(data.discount_amount)}%`}
                </span>
              ) : (
                <span className="block h-6"></span>
              )}
              <div className="group-hover:scale-105 ease-in-out duration-700 z-0 max-w-[240px] product_card_img">
                <LazyLoadImage
                  loading="lazy"
                  src={data?.cover_image}
                  alt={data.title}
                  effect="blur"
                className="h-full w-full object-contain object-center"
                />
              </div>
            </div>
          </div>

          <div className="w-full flex flex-col justify-start gap-y-2 px-4">
            <h3 className="text-white font-taviraj sm:font-bold sm:text-md overflow-hidden">
              {data?.title}
            </h3>
            <button className="w-full py-2 bg-lightGreen rounded-3xl text-white center my-3 font-dm">
              {t("viewDetails")}
            </button>
          </div>
        </div>
      </Link>
    ));

  return (
    <div className="max-w-[894px] grid grid-cols-2 gap-y-10 gap-x-[30px] lg:grid-cols-3">
      {productsListValue.count === 0 ? (
        <p>Not found this product :(</p>
      ) : Array.isArray(productsListValue) ? (
        renderProducts(productsListValue)
      ) : (
        renderProducts(productsListValue?.results || [])
      )}
    </div>
  );
});

export default ProductListComponent;
