import React from "react";
import "react-lazy-load-image-component/src/effects/blur.css";
import worldMap from "../videos/worldMap.webm";
import { t } from "i18next";

const Hero = () => {
  return (
    <>
      <div className="w-full relative max-h-[740px] 2xl:max-h-[1024px]">
        <div className="absolute text-white bottom-3 pr-6 sm:pr-0 sm:top-32 md:top-40 left-6 lg:left-32 z-[999]">
          <h1 className="font-taviraj font-medium text-xs sm:text-lg md:text-2xl lg:text-3xl xl:text-5xl animate__animated animate__fadeInUp">
            {t("h1HomeP")}
          </h1>
          <p
            className="w-full sm:max-w-[200px] md:max-w-[340px] xl:max-w-[438px] font-dm text-[10px] sm:text-xs 
          md:text-base lg:text-lg xl:text-xl leading-4 md:leading-7 md:mt-4 lg:mt-7 xl:mt-11
          animate__animated animate__fadeInUp"
          >
            {t("p1HomeP")}
          </p>
        </div>
        <video
          src={worldMap}
          autoPlay
          loop
          muted
          playsInline
          className="w-full max-h-[740px] 2xl:max-h-[1024px] h-full object-cover animate__animated animate__fadeIn animate__delay-1s"
        />
      </div>
    </>
  );
};

export default Hero;
