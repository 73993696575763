import React from "react";

const BrandsComponents = React.memo(({ data, brandStatus, brendClick }) => {
  

  return (
    <>
      <label htmlFor="brand-list" onClick={()=>brendClick(data.id)}>
        <input type="radio" name="brand" id="brand-list" value="Test" /> {data.name}
      </label>
    </>
  );
});

export default BrandsComponents;
