import React, { useEffect, useState } from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import az from "../flags/az.gif";
import en from "../flags/us.gif";
import ru from "../flags/ru.gif";
import ge from "../flags/ge.gif";
import tr from "../flags/tr.png";
import kz from "../flags/kz.png";
import uz from "../flags/uz.png";
import { useTranslation } from "react-i18next";

const LanguageDropdown = () => {
  const { i18n } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const [selectedLanguage, setSelectedLanguage] = useState(null);

  const languages = [
    { id: 1, language: "AZ", flag: az },
    { id: 2, language: "EN", flag: en },
    { id: 3, language: "RU", flag: ru },
    { id: 4, language: "KA", flag: ge },
    { id: 5, language: "TR", flag: tr },
    { id: 6, language: "KZ", flag: kz }, // Казахский
    { id: 7, language: "UZ", flag: uz }, // Узбекский
  ];

  // Устанавливаем выбранный язык на основе i18next при монтировании компонента
  useEffect(() => {
    if (i18n.language) {
      const userLang = i18n.language.toUpperCase(); // Получаем язык из  i18next
      setSelectedLanguage(languages.find((lang) => lang.language === userLang));
    }
  }, [i18n.language]);

  const changeLanguage = (language) => {
    
    window.location.href = `/`;
    // navigate(`/${language.language.toLowerCase()}`);

    i18n.changeLanguage(language.language); // Передаем только код языка

    setSelectedLanguage(language);
    setIsOpen(false);
  };

  
  

  return (
    <>
      <div className="w-[85px] lg:w-[110px] relative lg:ml-20 pl-[10px] py-2 bg-indigo-500 cursor-pointer rounded-t-lg">
        <div
          onClick={() => setIsOpen(!isOpen)}
          className="flex items-center justify-between"
        >
          <button className="flex items-center gap-x-2 ">
            <div className="w-6 h-4 rounded">
              <img
                src={selectedLanguage?.flag}
                alt={selectedLanguage?.language}
                className="w-full h-full rounded"
              />
            </div>
            <div className="text-[#5f5753]">{selectedLanguage?.language}</div>
          </button>
          <div className="text-[#5f5753] hidden lg:block">
            <IoMdArrowDropdown size={20} />
          </div>
        </div>
        {isOpen && (
          <div
            className="absolute w-full top-10 left-0 cursor-pointer bg-white border-[#D4D0CA] rounded-b-lg"
            style={{ zIndex: "55" }}
          >
            {languages.map((item, i) => (
              <div
                key={i}
                onClick={() => changeLanguage(item)}
                className="flex items-center gap-x-2 border border-x-0 border-b-0 border-[#D4D0CA] hover:bg-[#D4D0CA] py-2 px-[10px]"
              >
                <div className="w-6 h-4 rounded">
                  <img
                    src={item.flag}
                    alt={item.language}
                    className="w-full h-full rounded"
                  />
                </div>
                <div className="text-[#5f5753]">{item.language}</div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default LanguageDropdown;
