import React from "react";
import { CgMenuGridR } from "react-icons/cg";
import { GrFormAdd, GrFormSubtract } from "react-icons/gr";

const CategoryListMobVersion = React.memo(
  ({
    handleCatalog,
    catalog,
    catListValue,
    callCategoryIDlist,
    toggleSubcategories,
    expandedCategories,
    callSubCategoryIDlist,
  }) => {
    // Обработчик нажатия на категорию
    const handleCategoryClick = (item) => {
      if (item?.subcategories?.length > 0) {
        // Если есть подкатегории, показываем их
        toggleSubcategories(item.id);
      } else {
        // Если подкатегорий нет, вызываем список товаров
        callCategoryIDlist(item.id);
      }
    };

    return (
      <div className="block md:hidden mb-5">
        <div className="max-w-[580px] mx-auto flex items-center justify-end px-2 h-10">
          <div className="relative">
            <div
              className="flex justify-between items-center"
              onClick={handleCatalog}
            >
              <CgMenuGridR size={35} className="text-white" />
            </div>
            <ul
              className={`absolute w-[300px] top-10 right-0 text-white z-20 cursor-pointer border bg-[#5f6b7c] transition-all duration-300 ease-in-out rounded
                      ${
                        catalog
                          ? "top-10 right-0"
                          : "absolute opacity-0 -right-[500px]"
                      }`}
            >
              {catListValue?.categories?.map((item) => (
                <li
                  key={item?.id}
                  className="px-3 py-1 border border-x-0 border-t-0 border-[#eaeaea] "
                >
                  <div className="flex justify-between items-center">
                    <span onClick={() => handleCategoryClick(item)}>
                      {item?.title}
                    </span>
                    {item?.subcategories?.length > 0 && (
                      <button
                        onClick={() => toggleSubcategories(item.id)}
                        className="text-white text-xl ml-2"
                      >
                        {expandedCategories[item.id] ? (
                          <GrFormSubtract />
                        ) : (
                          <GrFormAdd />
                        )}
                      </button>
                    )}
                  </div>
                  <div
                    className={`overflow-hidden transition-max-height duration-700 ease-in-out ${
                      expandedCategories[item.id] ? "max-h-96" : "max-h-0"
                    }`}
                  >
                    <ul className="pl-4 mt-2">
                      {item.subcategories.map((subcategory) => (
                        <li
                          key={subcategory.id}
                          className="px-2 py-1 border border-x-0 border-b-0 border-[#eaeaea]"
                          onClick={() => callSubCategoryIDlist(subcategory?.id)}
                        >
                          {subcategory?.title}
                        </li>
                      ))}
                    </ul>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
);

export default CategoryListMobVersion;
