import React from "react";
import Products from "../components/Products";
import { Helmet } from "react-helmet-async";
import img from "../images/logo.png";
import { useTranslation } from "react-i18next";

const ProductsPage = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t("productsTit")}</title>
        <meta name="keywords" content={t("keywordsProducts")} />
        <meta name="description" content={t("descriptionProducts")} />

        <meta property="og:locale" content={i18n.language.replace("-", "_")} />
        <meta property="og:site_name" content="BUZ-GROUP.COM" />
        <meta property="og:title" content={t("ogTitleProducts")} />
        <meta property="og:description" content={t("ogDescriptionProducts")} />
        <meta property="og:image" content={img} />
        <meta property="og:image:width" content="600" />
        <meta property="og:image:height" content="315" />
        <meta property="og:url" content="https://buz-group.com" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={t("twitterTitleProducts")} />
        <meta
          name="twitter:description"
          content={t("twitterDescriptionProducts")}
        />
        <meta name="twitter:image" content={img} />
      </Helmet>
      <Products />
    </>
  );
};

export default ProductsPage;
