import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { setupStore } from "./redux/store";
import { Provider } from "react-redux";
import "./18n";
import 'react-responsive-pagination/themes/classic.css';
import "animate.css";



const store = setupStore();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
