import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import ProductsPage from "./pages/ProductsPage";
import NewsPage from "./pages/NewsPage";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
// import UnderNav from "./components/UnderNav";
import SingleNewsPage from "./pages/SingleNewsPage";
import SingleProdPage from "./pages/SingleProdPage";
import ContactPage from "./pages/ContactPage";
import About from "./pages/About";
import PartnersPage from "./pages/PartnersPage";
import NotFound from "./pages/NotFound";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { HelmetProvider } from "react-helmet-async";

function App() {

  // Для определения тега <html lang=""></html>
  const { i18n } = useTranslation();
  useEffect(() => {
    if (i18n.language && i18n.language !=='dev' )  { // Проверяем, что язык загружен
      // Устанавливаем <html lang="lang"></html>
      document.documentElement.setAttribute("lang", i18n.language);
    }
  }, [i18n.language]);

  return (
    
    <BrowserRouter basename="/">
      <HelmetProvider>
        <Navbar />
        {/* <UnderNav /> */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/:lang/products" element={<ProductsPage />} />
          <Route path="/:lang/singleprod/:id/:productname" element={<SingleProdPage />} />
          <Route path="/:lang/news" element={<NewsPage />} />
          <Route path="/:lang/singlenewspage/:id" element={<SingleNewsPage />} /> 
          <Route path="/:lang/partners" element={<PartnersPage />} />
          <Route path="/:lang/about" element={<About />} />
          <Route path="/:lang/contact" element={<ContactPage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </HelmetProvider>
    </BrowserRouter>
  );
}

export default App;
