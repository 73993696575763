import React from "react";
import Contact from "../components/Contact";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import img from "../images/logo.png";

const ContactPage = () => {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <Helmet>
        <title>{t("contactTit")} </title>
        <meta name="keywords" content={t("keywordsContact")} />
        <meta name="description" content={t("descriptionContact")} />

        <meta property="og:locale" content={i18n.language.replace("-", "_")} />
        <meta property="og:site_name" content="BUZ-GROUP.COM" />
        <meta property="og:title" content={t("ogTitleContact")} />
        <meta property="og:description" content={t("ogDescriptionContact")} />
        <meta property="og:image" content={img} />
        <meta property="og:image:width" content="600" />
        <meta property="og:image:height" content="315" />
        <meta property="og:url" content="https://buz-group.com" />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={t("twitterTitleContact")} />
        <meta
          name="twitter:description"
          content={t("twitterDescriptionContact")}
        />
        <meta name="twitter:image" content={img} />
      </Helmet>
      <Contact />
    </div>
  );
};

export default ContactPage;
